import React, { useState } from "react";
import SidebarMenu from "../components/SettingsSidebar";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import NeedHelp from "./NeedHelp";
import SignOutButton from "./SignOut";

const SettingsPage = () => {
  const [activeMenuItem, setActiveMenuItem] = useState("Edit Profile");

  const handleMenuClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  const renderActiveComponent = () => {
    switch (activeMenuItem) {
      case "Edit Profile":
        return <EditProfile />;
      case "Change Password":
        return <ChangePassword />;
      case "Need Help":
        return <NeedHelp />;
      default:
        return null;
    }
  };

  return (
    <div className=" mt-5 pb-10 ">
      <div className="flex justify-between px-6">
        <h1 className="text-lg font-semibold text-gray-900 ">Settings</h1>
        <SignOutButton />
      </div>
      <div className="flex p-6 ">
        <SidebarMenu handleMenuClick={handleMenuClick} />
        <div className="w-2/3 px-6 py-16   bg-[#06652e]">
          {renderActiveComponent()}
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
