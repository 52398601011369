/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFacilityTemplateFile,
  setFacilityTemplateUploadStatus,
} from "../../redux/reducers/facilitySlice";
import axios from "axios";
import { API_ENDPOINTS } from "../../api/endpoints/ApiEndpoints";
import UploadedDataTable from "./UploadedDataTable";
import { toast } from "react-toastify";
import {
  setFacilityName,
  setFacilityType,
  setFacilityAddress,
  setFacilityCity,
  setFacilityState,
  setFacilityTypes,
  setFacilityId,
} from "../../redux/reducers/facilitySlice";

const UploadHistoricalData = ({
  handlePrevStep,
  handleNextStep,
  uploadResponse,
  setUploadResponse,
}) => {
  const dispatch = useDispatch();
  const facilityId = useSelector((state) => state.facility.facilityId);

  const [file, setFile] = useState(null);
  const [dataUploaded, setDataUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileError, setFileError] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileError(null);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setFileError("Please select a file.");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(API_ENDPOINTS.UPLOAD_FILE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      });

      dispatch(setFacilityTemplateFile(response.data));
      dispatch(setFacilityTemplateUploadStatus("success"));
      setUploadResponse(response.data);
      setDataUploaded(true);
      handleNextStep();
    } catch (error) {
      console.error("Error uploading file:", error);
      dispatch(setFacilityTemplateUploadStatus("error"));
    }

    setIsLoading(false);
  };

  const handleDownload = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(API_ENDPOINTS.DOWNLOAD_SAMPLE, {
        responseType: "blob",
        headers: {
          Authorization: token,
        },
      });

      if (response.status !== 200) {
        throw new Error(`Download failed with status ${response.status}`);
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = "template.csv";
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Error downloading template");
    }
  };

  // useEffect(() => {
  //   if (!facilityId) {
  //     console.log("User does not have facilityId");
  //   } else {
  //     console.log("User has facilityId");
  //   }
  // }, [facilityId]);

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <div className="flex flex-col w-full max-w-2xl bg-white rounded-lg shadow-lg p-6">
        <div className="flex justify-center items-center mb-4">
          <h1 className="text-2xl font-bold text-[#1F2326]">
            {" "}
            Upload Historical data
          </h1>
        </div>
        {/* <label className="text-sm font-normal text-[#000000] tracking-wide">
          Upload Historical data
        </label> */}
        <input
          className="text-base p-2 border border-gray-300 rounded-lg mb-4"
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          disabled={!facilityId}
        />
        {fileError && <p className="text-red-500">{fileError}</p>}

        <p className="text-base font-bold text-[#1F2326] text-center mb-4">
          or
        </p>

        <div className="flex justify-between mt-4 gap-5">
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded w-full"
            onClick={handleDownload}
            disabled={!facilityId}
          >
            Download Template
          </button>

          <button
            className={`bg-[#06652e] hover:bg-[#06652e] text-white py-2 px-4 rounded w-full ${
              !facilityId || dataUploaded ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleUpload}
            disabled={!facilityId || dataUploaded || isLoading}
          >
            {isLoading ? "Uploading..." : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};



export default UploadHistoricalData;
