import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { viewInventoryTableData } from "../api/httprequests/ViewInventory";
import { setData, setSearchTerm } from "../redux/reducers/viewItemSlice";
import LoadingSpinner from "./LoadingSpinner";

import ProfileSettings from "./ProfileSettings";
import Pagination from "./pagination/Pagination";

const ViewItems = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { data, searchTerm } = useSelector((state) => state.viewItem);

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const offset = (currentPage - 1) * itemsPerPage;

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        dispatch(setData([]));
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      const viewItemData = await viewInventoryTableData(token, itemId);
      dispatch(setData(viewItemData));
      setIsLoading(false);
    };

    fetchData();
  }, [dispatch, itemId]);

  const handleBack = () => {
    navigate("/inventory");
  };



  const filteredData = useMemo(() => {
    return data
      ? data.filter((item) =>
          item.product.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];
  }, [data, searchTerm]);

  const totalResults = filteredData.length;
  const totalPages = Math.ceil(totalResults / itemsPerPage);

  const currentItems = filteredData.slice(offset, offset + itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  useEffect(() => {
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(1);
    }
  }, [currentPage, totalPages]);

  return (
    <div className="flex flex-col justify-between p-6">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="text-2xl md:text-4xl font-semibold text-gray-800 mt-4 md:mt-7 mb-4 md:mb-6 text-center md:text-left  flex gap-2">
          Facility Inventory Items
        </div>
        <ProfileSettings />
      </div>
      <div className="flex justify-between items-center mb-6 ">
        <div className="flex justify-between items-center ">
          <input
            type="text"
            className="bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-md py-2 px-4 block w-72 appearance-none leading-normal"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => dispatch(setSearchTerm(e.target.value))}
          />
        </div>
        <button
          className="bg-[#06652e] hover:bg-[#06652e] text-[#F8FBFF] font-normal py-2 px-4 rounded text-sm ml-2"
          onClick={handleBack}
        >
          Back to Inventory
        </button>
      </div>

      <div className="max-w-full overflow-x-auto">
        <table className="w-full text-center table-fixed">
          {/* Table headers */}
          <thead className="bg-[#06652e] h-16">
            <tr className="text-[#fff] text-base">
              <th className="w-1/4 py-2">Product</th>
              <th className="w-1/4 py-2">Category</th>
              <th className="w-1/4 py-2">SKU</th>
              <th className="w-1/4 py-2">Facility</th>
              <th className="w-1/4 py-2">Date Updated</th>
              <th className="w-1/4 py-2">Quantity</th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody>
            {currentItems.map((item, index) => (
              <tr
                key={index}
                className="border-b border-gray-400 border-opacity-20 bg-[#F8FBFF]"
              >
                <td className="w-1/4 py-2">{item.product}</td>
                <td className="w-1/4 py-2">{item.category}</td>
                <td className="w-1/4 py-2">{item.sku || "Pack"}</td>
                <td className="w-1/4 py-2">{item.facility}</td>
                <td className="w-1/4 py-2">{item.updated}</td>
                <td className="w-1/4 py-2">{item.qty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isLoading && <LoadingSpinner />}

      {!isLoading && filteredData.length === 0 && (
        <div className="text-center text-gray-500 mt-4">Data not available</div>
      )}

      {/* {!isLoading && (
        <div className="flex justify-center mt-2 text-gray-500">
          Showing {currentItems.length} of {totalResults} results
        </div>
      )} */}
      <div className="flex justify-end mb-4">
        <Pagination
          pageCount={totalPages}
          onPageChange={handlePageChange}
          forcePage={currentPage - 1}
        />
      </div>
    </div>
  );
};




export default ViewItems;
