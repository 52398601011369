import axios from "axios";
import { API_ENDPOINTS } from "../endpoints/ApiEndpoints";
import { toast } from "react-toastify";

export const getPredictionData = async (token) => {
  try {
    const response = await axios.get(API_ENDPOINTS.PREDICTION, {
      headers: {
        Authorization: token,
      },
    });

    const predictionData = response.data.data.details || [];
    const predictionCard = response.data.data || {
      total: 0,
      facility: 0,
      product: 0,
    };

    toast.success(response.data.messgae, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { predictionData, predictionCard };
  } catch (error) {
    return {
      predictionData: [],
      predictionCard: {
        total: 0,
        facility: 0,
        product: 0,
      },
    };
  }
};
