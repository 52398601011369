import React from "react";
import { useDispatch } from "react-redux";
import { signOut } from "../redux/reducers/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SignOutButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.removeItem("token");
    dispatch(signOut());
    navigate("/");
    toast.success("Sign-out successful!");
  };

  return (
    <button
      type="button"
      onClick={handleSignOut}
      className="bg-[#06652e] hover:bg-[#06652e] text-white font-bold py-2 px-4 rounded"
    >
      Sign Out
    </button>
  );
};

export default SignOutButton;
