/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import addicon from "../../assets/add.png";
import axios from "axios";
import "../../pages/styles/Inventory.css";
import {
  setFacilityData,
  setFacilityId,
} from "../../redux/reducers/facilitySlice";
import { useDispatch, useSelector } from "react-redux";
import { getFacilityTableData } from "../../api/httprequests/FacilityTableData";
import { API_ENDPOINTS } from "../../api/endpoints/ApiEndpoints";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import { toast } from "react-toastify";
import Pagination from "../pagination/Pagination";
import useSortableTable from "../../hooks/useSortableTable";

const FacilityTable = () => {
  const [isLoadingCalculate, setIsLoadingCalculate] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { facilityData } = useSelector((state) => state.facility);
  const token = localStorage.getItem("token");

  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showAllItems, setShowAllItems] = useState(false);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handleModalOpen = () => {
    navigate("/facilities/add");
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        dispatch(setFacilityData([]));
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);

        const facilityTableData = await getFacilityTableData(token);
        dispatch(setFacilityData(facilityTableData));
      } catch (error) {
        console.error("Error fetching facility data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, token]);

  const filteredFacilityData =
    facilityData.length > 0
      ? facilityData.filter((item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : [];

  const { sortedData, sortBy, sortDirection, handleSort } =
    useSortableTable(filteredFacilityData, "name", "asc");

  useEffect(() => {
    setShowAllItems(false);
    setCurrentPage(1);
  }, [searchQuery]);

  const handleStatusToggle = async (id) => {
    try {
      const item = facilityData.find((currentItem) => currentItem.id === id);
      if (!item) {
        throw new Error("Facility not found");
      }

      const newStatus = item.status === "1" ? false : true;

      const endpoint = newStatus
        ? `${API_ENDPOINTS.ENABLE_FACILITY}`
        : `${API_ENDPOINTS.DISABLE_FACILITY}`;

      await axios.put(
        endpoint,
        { facility_id: id },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const updatedItems = facilityData.map((currentItem) => {
        if (currentItem.id === id) {
          return { ...currentItem, status: newStatus ? "1" : "0" };
        }
        return currentItem;
      });

      dispatch(setFacilityData(updatedItems));
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };

  const handleFetchUsers = async (id) => {
    try {
      setIsLoading(true);
      dispatch(setFacilityId(id));
      navigate(`/facilities/users/${id}/`);
    } catch (error) {
      toast.error("Error fetching users");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentItems = showAllItems
    ? sortedData
    : sortedData.slice(startIndex, endIndex);

  const hasNoItems = filteredFacilityData.length === 0;

  const totalPages = Math.ceil(filteredFacilityData.length / itemsPerPage);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  return (
    <>
      <div className="flex justify-center items-center mt-8 mb-8 md:justify-between md:items-center">
        <div>
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-md py-2 px-4 block w-72 appearance-none leading-normal"
          />
        </div>
        <div>
          <button
            className="bg-[#06652e] hover:bg-[#06652e] text-[#F8FBFF] font-normal py-2 px-6 rounded text-sm flex items-center"
            onClick={() => handleModalOpen()}
          >
            Add new Facility
            <img src={addicon} alt="" className="ml-2" />
          </button>
        </div>
      </div>

      <div className="max-w-full overflow-x-auto">
        <table className="w-full  text-center">
          <thead className="bg-[#06652e] h-16">
            <tr className="text-[#fff] text-base">
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("id")}
              >
                ID{" "}
                {sortBy === "id" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("name")}
              >
                Facility{" "}
                {sortBy === "name" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("facility_type_name")}
              >
                Type{" "}
                {sortBy === "facility_type_name" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("address")}
              >
                Address{" "}
                {sortBy === "address" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th className="px-4 py-1">Action</th>
              <th className="px-4 py-1">Facility User</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => (
              <tr
                key={item.id}
                className="border-b border-gray-400 border-opacity-20 bg-[#F8FBFF]"
              >
                <td className="px-4 py-1 ">{item.id}</td>
                <td className="px-4 py-1 ">{item.name}</td>
                <td className="px-4 py-1 ">{item.facility_type_name}</td>
                <td className="px-4 py-1 ">{item.address}</td>
                <td className="px-4 py-1">
                  <button
                    className={`hover:text-${
                      item.status === "1" ? "[#D33B27]" : "[#06652e]"
                    } text-${
                      item.status === "1" ? "[#D33B27]" : "[#06652e]"
                    } font-normal py-2 text-sm`}
                    onClick={() => handleStatusToggle(item.id)}
                  >
                    {item.status === "1" ? "disable" : "enable"}
                  </button>
                </td>
                {isLoadingCalculate ? (
                  <td className="px-4 py-1">
                    <LoadingSpinner />
                  </td>
                ) : (
                  <td className="px-4 py-1">
                    <button
                      className="bg-[#06652e] hover:bg-[#2C2E82] text-[#F8FBFF] font-normal py-1 px-2 rounded text-sm"
                      onClick={() => handleFetchUsers(item.id)}
                    >
                      View Users
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end items-center mb-2">
        {!showAllItems && filteredFacilityData.length > itemsPerPage && (
          <div className="flex justify-center ">
            <Pagination
              pageCount={totalPages}
              onPageChange={handlePageChange}
              forcePage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </>
  );
};




export default FacilityTable;
