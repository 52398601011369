import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const passwordSlice = createSlice({
  name: "password",
  initialState,
  reducers: {
    setCurrentPassword: (state, action) => {
      state.currentPassword = action.payload;
    },
    setNewPassword: (state, action) => {
      state.newPassword = action.payload;
    },
    setConfirmNewPassword: (state, action) => {
      state.confirmNewPassword = action.payload;
    },
    resetPassword: (state) => {
      state.currentPassword = "";
      state.newPassword = "";
      state.confirmNewPassword = "";
    },
  },
});

export const {
  setCurrentPassword,
  setNewPassword,
  setConfirmNewPassword,
  resetPassword,
} = passwordSlice.actions;

export default passwordSlice.reducer;
