import React, { useCallback, useEffect, useState } from 'react'
import { getPendingFacilities } from '../../api/httprequests/getPendingFacilities';
import useSortableTable from '../../hooks/useSortableTable';
import LoadingSpinner from '../LoadingSpinner';
import Pagination from '../pagination/Pagination';
import { differenceInDays } from 'date-fns';

export default function PendingFacilitiesTable() {

  // const [isLoadingCalculate, setIsLoadingCalculate] = useState(false);
  // const { facilityData } = useSelector((state) => state.facility);
  const [pendingFacilities, setPendingFacilities] = useState([])
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showAllItems, setShowAllItems] = useState(false);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const filteredFacilityData = pendingFacilities.length > 0 ? 
  pendingFacilities.filter((item) => (item?.name || "").toLowerCase().includes(searchQuery.toLowerCase())) : 
  [];

  const { sortedData, sortBy, sortDirection, handleSort } = useSortableTable(filteredFacilityData, "name", "asc");

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentItems = showAllItems ? sortedData : sortedData.slice(startIndex, endIndex);

  const hasNoItems = filteredFacilityData.length === 0;

  const totalPages = Math.ceil(filteredFacilityData.length / itemsPerPage);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    const data = await getPendingFacilities()
    setPendingFacilities(data?.details || [])
    setIsLoading(false)
  }, [])
  
  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    setShowAllItems(false);
    setCurrentPage(1);
  }, [searchQuery]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="pt-10 px-8">
      <h1 className="mb-4 text-2xl">Pending Facility Updates</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-md py-2 px-4 block w-72 appearance-none leading-normal"
        />
      </div>
      <div className="max-w-full overflow-x-auto">
        <table className="w-full  text-center">
          <thead className="bg-[#06652e] h-16">
            <tr className="text-[#fff] text-base">
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("facility")}
              >
                Facility{" "}
                {sortBy === "facility" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("updated")}
              >
                Last Updated{" "}
                {sortBy === "updated" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("address")}
              >
                Updated Since{" "}
                {sortBy === "address" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => (
              <tr
                key={item.id}
                className="border-b border-gray-400 border-opacity-20 bg-[#F8FBFF]"
              >
                {/* <td className="px-4 py-4 ">{item.id}</td> */}
                <td className="px-4 py-2 ">{item.facility}</td>
                <td className="px-4 py-2 ">{new Date((item.updated).slice(0, -2)).toLocaleString()}</td>
                <td className="px-4 py-2 ">{differenceInDays(new Date(), new Date((item.updated).slice(0, -2))) + " day(s) ago"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end items-center mb-2">
        {!showAllItems && filteredFacilityData.length > itemsPerPage && (
          <div className="flex justify-center ">
            <Pagination
              pageCount={totalPages}
              onPageChange={handlePageChange}
              forcePage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </div>
  )
}
