import axios from "axios";
import { API_ENDPOINTS } from "../endpoints/ApiEndpoints";

export const getMyInventoryTableData = async (token) => {
  try {
    const response = await axios.get(API_ENDPOINTS.MY_INVENTORY, {
      headers: {
        Authorization: token,
      },
    });

    return response.data.data;
  } catch (error) {
     return []
  }
};
