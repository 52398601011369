/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  setCurrentPassword,
  setNewPassword,
  setConfirmNewPassword,
  resetPassword,
} from "../redux/reducers/passwordSlice";
import { toast } from "react-toastify";
import { FiEye, FiEyeOff } from "react-icons/fi";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { currentPassword, newPassword, confirmNewPassword } = useSelector(
    (state) => state.password
  );
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleToggleCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }

      // Validation: Check if required fields are empty
      if (!currentPassword || !newPassword || !confirmNewPassword) {
        toast.error("Please fill in all required fields");
        return;
      }

      if (newPassword !== confirmNewPassword) {
        toast.error("New password and confirm password must match");
        return;
      }

      const response = await axios.put(
        "https://onebox.lifebanktest.com/api/admin/change/password",
        {
          new_password: newPassword,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

 

      dispatch(resetPassword());
      toast.success("Password changed successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error changing password. Please try again");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Current Password */}
        <div className="flex flex-col">
          <label
            className="text-white text-sm font-normal mb-2"
            htmlFor="currentpassword"
          >
            Your current password
          </label>
          <div className="relative">
            <input
              className="border border-gray-300 p-2 mb-4 outline-none bg-[#fff] w-full"
              type={showCurrentPassword ? "text" : "password"}
              name="currentpassword"
              id="currentpassword"
              value={currentPassword}
              onChange={(e) => dispatch(setCurrentPassword(e.target.value))}
              placeholder="Your current password"
            />
            <button
              type="button"
              onClick={handleToggleCurrentPassword}
              className="absolute right-3 top-1/3 transform -translate-y-1/2 text-gray-500 focus:outline-none"
            >
              {showCurrentPassword ? <FiEyeOff /> : <FiEye />}
            </button>
          </div>
        </div>

        {/* New Password */}
        <div className="flex flex-col">
          <label
            className="text-white text-sm font-normal mb-2"
            htmlFor="newpassword"
          >
            New password
          </label>
          <div className="relative">
            <input
              className="border border-gray-300 p-2 mb-4 outline-none bg-[#fff] w-full"
              type={showNewPassword ? "text" : "password"}
              name="newpassword"
              id="newpassword"
              value={newPassword}
              onChange={(e) => dispatch(setNewPassword(e.target.value))}
              placeholder="New password"
            />
            <button
              type="button"
              onClick={handleToggleNewPassword}
              className="absolute right-3 top-1/3 transform -translate-y-1/2 text-gray-500 focus:outline-none"
            >
              {showNewPassword ? <FiEyeOff /> : <FiEye />}
            </button>
          </div>
        </div>

        {/* Confirm New Password */}
        <div className="flex flex-col">
          <label
            className="text-white text-sm font-normal mb-2"
            htmlFor="confirmnewpassword"
          >
            Confirm new password
          </label>
          <div className="relative">
            <input
              className="border border-gray-300 p-2 mb-4 outline-none bg-[#fff] w-full"
              type={showConfirmPassword ? "text" : "password"}
              name="confirmnewpassword"
              id="confirmnewpassword"
              value={confirmNewPassword}
              onChange={(e) => dispatch(setConfirmNewPassword(e.target.value))}
              placeholder="Confirm new password"
            />
            <button
              type="button"
              onClick={handleToggleConfirmPassword}
              className="absolute right-3 top-1/3 transform -translate-y-1/2 text-gray-500 focus:outline-none"
            >
              {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
            </button>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex lg:justify-start lg:items-start md:justify-center md:items-center w-full">
          <button
            className="w-full sm:w-auto bg-[#06652e] hover:bg-[#06652e] text-white font-semibold py-2 px-4 sm:py-3 sm:px-6 lg:text-lg rounded"
            type="submit"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;

