import React from "react";
import { useNavigate } from "react-router-dom";

const OrderSuccess = ({ notification, route }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/${route}`);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="flex justify-end items-center mb-5">
        <button
          className="bg-[#06652e] hover:bg-[#06652e] text-[#F8FBFF] font-normal py-2 px-4 rounded text-sm cursor-pointer"
          onClick={handleBack}
        >
          View Orders
        </button>
      </div>
      <div className="flex flex-col items-center space-y-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-16 w-16 text-green-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M9 2a7 7 0 100 14A7 7 0 009 2zm0 12a5 5 0 100-10 5 5 0 000 10zm4.707-7.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-2-2a1 1 0 111.414-1.414L8 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4z"
            clipRule="evenodd"
          />
        </svg>
        <h1 className="text-3xl font-bold text-gray-800">{notification}</h1>
      </div>
    </div>
  );
};

export default OrderSuccess;
