import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [] };

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setData } = inventorySlice.actions;
export default inventorySlice.reducer;
