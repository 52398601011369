
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  facility_id: null,
  username: "",
  password: "",
  email: "",
  phone: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {

    setFacilityId: (state, action) => {
      state.facility_id = action.payload;
    },

    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    resetUser: (state) => {
      state.username = "";
      state.password = "";
      state.email = "";
      state.phone = "";
    },
  },
});

export const {
  setUsername,
  setPassword,
  setEmail,
  setPhone,
  resetUser,
  setFacilityId,
} = userSlice.actions;

export default userSlice.reducer;
