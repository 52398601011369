import React from "react";
import ReactPaginate from "react-paginate";
import "./pagination.css";

const Pagination = ({ pageCount, onPageChange, forcePage }) => {
  return (
    <ReactPaginate
      previousLabel={"Previous"}
      nextLabel={"Next"}
      breakLabel={"..."}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      onPageChange={onPageChange}
      containerClassName={"pagination-container"}
      previousClassName={"pagination-item"}
      nextClassName={"pagination-item"}
      pageClassName={"pagination-item"}
      breakClassName={"pagination-item"}
      activeClassName={"pagination-item-active"}
      disabledClassName={"pagination-item-disabled"}
      previousLinkClassName={"pagination-link"}
      nextLinkClassName={"pagination-link"}
      pageLinkClassName={"pagination-link"}
      breakLinkClassName={"pagination-link"}
      activeLinkClassName={"pagination-link-active"}
      forcePage={forcePage}
    />
  );
};

export default Pagination;