import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  searchTerm: "",
};

const viewItemSlice = createSlice({
  name: "viewItem",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
  },
});

export const { setData, setSearchTerm } = viewItemSlice.actions;
export default viewItemSlice.reducer;
