/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchInventoryData,
  selectInventoryData,
  selectInventoryLoading,
  selectInventoryError,
} from "../../redux/reducers/myInventorySlice";
import LoadingSpinner from "../LoadingSpinner";
import ErrorComponent from "../notifcations/ErrorNotication";
import Pagination from "../pagination/Pagination";
import Modal from "../Modal";
import Button from "../Button";
import UpdateInventory from "../forms/UpdateInventory";
import { Link } from "react-router-dom";
import useSortableTable from "../../hooks/useSortableTable";



const MyInventoryTable = ({ searchTerm }) => {
  const dispatch = useDispatch();
  const inventoryData = useSelector(selectInventoryData);
  const isLoading = useSelector(selectInventoryLoading);
  const error = useSelector(selectInventoryError);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInventoryId, setSelectedInventoryId] = useState(null);

  useEffect(() => {
    dispatch(fetchInventoryData());
  }, [dispatch]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleItemClick = (inventory_id) => {
    setSelectedInventoryId(inventory_id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Filtered data based on search term
  const filteredData = inventoryData.filter((item) =>
    (item.product || '').toLowerCase().includes((searchTerm || '').toLowerCase())
  );

  // Pagination
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredData.slice(offset, offset + itemsPerPage);

  const { sortedData, sortBy, sortDirection, handleSort } = useSortableTable(currentItems, 'product', 'asc');

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {filteredData.length > 0 ? (
            <div className="max-w-full overflow-x-auto">
              <table className="w-full text-center table-fixed">
                <thead className="bg-[#06652e] h-16">
                  <tr className="text-[#fff] text-base">
                    {/* <th className="px-2 py-1" onClick={() => handleSort('id')}>
                      ID {sortBy === 'id' && (sortDirection === 'asc' ? '▲' : '▼')}
                    </th> */}
                    <th className="px-4 py-1 w-1/7" onClick={() => handleSort('product')}>
                      Product {sortBy === 'product' && (sortDirection === 'asc' ? '▲' : '▼')}
                    </th>
                    <th className="px-4 py-1 w-1/7" onClick={() => handleSort('category')}>
                      Category {sortBy === 'category' && (sortDirection === 'asc' ? '▲' : '▼')}
                    </th>
                    <th className="px-4 py-1 w-1/7" onClick={() => handleSort('formulation')}>
                      Formulation {sortBy === 'formulation' && (sortDirection === 'asc' ? '▲' : '▼')}
                    </th>
                    <th className="px-4 py-1 w-1/7" onClick={() => handleSort('sku')}>
                      SKU {sortBy === 'sku' && (sortDirection === 'asc' ? '▲' : '▼')}
                    </th>
                    <th className="px-4 py-1 w-1/7" onClick={() => handleSort('qty')}>
                      Qty.available {sortBy === 'qty' && (sortDirection === 'asc' ? '▲' : '▼')}
                    </th>
                    <th className="px-4 py-1 w-1/7" onClick={() => handleSort('pricing')}>
                      Price(₦) {sortBy === 'pricing' && (sortDirection === 'asc' ? '▲' : '▼')}
                    </th>
                    <th className="px-4 py-1 w-1/7" onClick={() => handleSort('updated')}>
                      Last updated {sortBy === 'updated' && (sortDirection === 'asc' ? '▲' : '▼')}
                    </th>
                    <th className="px-4 py-1 w-1/7">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData.map((item) => (
                    <tr key={item.id} className="border-b bg-[#F8FBFF]">
                      {/* <td className="px-2 py-1 table-cell ">{item.id}</td> */}
                      <td className="px-4 py-1 table-cell w-1/7">
                        {item.product}
                      </td>
                      <td className="px-4 py-1 table-cell w-1/7">
                        {item.category || "Not Specified"}
                      </td>
                      <td className="px-4 py-1 table-cell w-1/7">
                        {item.formulation}
                      </td>
                      <td className="px-4 py-1 table-cell w-1/7">
                        {item.sku || "UGG-BB-PUR-07"}
                      </td>
                      <td className="px-4 py-1 table-cell w-1/7">{item.qty}</td>
                      <td className="px-4 py-1 table-cell w-1/7">
                        {item.pricing || "₦ 500"}
                      </td>

                      <td className="px-4 py-1 table-cell w-1/7 text-center">
                        {item.updated}
                      </td>
                      <td className="px-4 py-1 table-cell w-1/7 text-center">
                        <Link
                          onClick={() => handleItemClick(item.id)}
                          className="text-[#2C2E82] hover:text-[#2C2E82]"
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center text-gray-500 mt-4">
              Data not available
            </div>
          )}
        </div>
      )}

      {error && <ErrorComponent message={error} />}
      <div className="flex justify-end  mb-2">
        <Pagination
          pageCount={pageCount}
          onPageChange={handlePageChange}
          forcePage={currentPage}
        />
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <h2 className="text-xl font-bold mb-4">Update Inventories</h2>
          <UpdateInventory
            inventory_id={selectedInventoryId}
            closeModal={closeModal}
          />
        </Modal>
      )}
    </div>
  );
};



export default MyInventoryTable;
