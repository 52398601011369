import React from "react";

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-[70%]">
    <div className="fixed inset-0 bg-white opacity-75"></div>
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[#06652e]"></div>
    </div>
  </div>
);

export default LoadingSpinner;
