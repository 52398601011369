import axios from "axios";
import { API_ENDPOINTS } from "../endpoints/ApiEndpoints";

export const getPendingFacilities = async () => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(API_ENDPOINTS.PENDING_FACILITY_UPDATES, {
      headers: {
        Authorization: token,
      },
    });

    return response?.data?.data || 0
  } catch (error) {
    return 0
  }
};
