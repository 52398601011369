
const Card = ({ icon, count, description, background }) => {
  return (
    <div
      className={`rounded-lg shadow-md ${background} overflow-hidden`}
      style={{
        minWidth: "100px",
        minHeight: "200px",
        width: "100%",
        maxWidth: "400px",
      }}
    >
      <div className="p-6">
        <img
          src={icon}
          className="flex items-center justify-center w-12 h-12 mx-auto"
          alt="icon"
        />
        <p className="text-4xl mt-5 mb-2 font-semibold text-white text-center">
          {count}
        </p>
        <p className="text-lg font-semibold text-white text-center">
          {description}
        </p>
      </div>
    </div>
  );
};

export default Card;














