import React, { useState, useEffect, useRef } from "react";


const PopupNotification = ({ message }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notificationRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <div
      ref={notificationRef}
      className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-4 p-4 bg-white border border-gray-300 rounded-md shadow-md ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="flex justify-between items-center">
        <div className="text-lg">{message}</div>
       
      </div>
    </div>
  );
};

export default PopupNotification;
