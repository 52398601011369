import axios from "axios";
import { API_ENDPOINTS } from "../endpoints/ApiEndpoints";

export const viewInventoryTableData = async (token, itemID) => {
  try {
    const response = await axios.get(`${API_ENDPOINTS.VIEW_ITEM}/${itemID}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};
