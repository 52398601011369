import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const fetchProducts = createAsyncThunk(
  "routineOrder/fetchProducts",
  async (itemId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `https://onebox.lifebanktest.com/api/admin/process/${itemId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const responseData = response.data;
      if (responseData.status) {
        return responseData.data;
      }
    } catch (error) {
      toast.error("Error fetching products");
      return rejectWithValue(error.response.data);
    }
  }
);

//Facility
export const fetchFacility = createAsyncThunk(
  "emergencyOrder/fetchFacility",
  async (itemId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `https://onebox.lifebanktest.com/api/admin/process/${itemId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const responseData = response.data;
      if (responseData.status) {
        return responseData.facility;
      }
    } catch (error) {
      toast.error("Error fetching products");
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendOrder = createAsyncThunk(
  "routineOrder/sendOrder",
  async ({ itemId, products }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `https://onebox.lifebanktest.com/api/admin/process/${itemId}`,
        {
          products: products.map((item) => ({
            id: item.id,
            name: item.name,
            qty: item.qty.toString(),
            atp: item.atp,
          })),
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      toast.error("Failed to send order");
      return rejectWithValue(error.response.data);
    }
  }
);

//
export const sendFacility = createAsyncThunk(
  "emergencyOrder/sendFacility",
  async ({ itemId, facility }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `https://onebox.lifebanktest.com/api/admin/process/${itemId}`,
        {
          facility: facility.map((item) => ({
            id: item.id,
            name: item.name,
            qty: item.address,
            atp: item.city,
          })),
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      toast.error("Failed to send order");
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  facility: {},
  products: [],
  isLoading: true,
  isProcessed: false,
  editableQuantities: {},
  atp: {},
};

const routineOrderSlice = createSlice({
  name: "routineOrder",
  initialState,
  reducers: {
    setFacility: (state, action) => {
      state.facility = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsProcessed: (state, action) => {
      state.isProcessed = action.payload;
    },
    setEditableQuantities: (state, action) => {
      const { itemId, newQty } = action.payload;
      state.editableQuantities = {
        ...state.editableQuantities,
        [itemId]: newQty,
      };
    },

    setAtp: (state, action) => {
      const { productId, value } = action.payload;
      state.atp[productId] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFacility.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchFacility.fulfilled, (state, action) => {
        state.facility = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(sendOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendOrder.fulfilled, (state) => {
        state.isProcessed = true;
        state.isLoading = false;
      })
      .addCase(fetchFacility.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(sendOrder.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setProducts,
  setIsLoading,
  setIsProcessed,
  setEditableQuantities,
  setAtp,
  setFacility,
} = routineOrderSlice.actions;

export default routineOrderSlice.reducer;
