// src/components/TabNavigation.js
import React from "react";

function TabNavigation({ activeTab}) {
  const tabs = ["Add Facility", "Create User", "Upload Historical Data", "Verify Historical Data"];

  return (
    <div className="flex justify-center items-center w-[100%] mb-2">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`px-4 py-2 uppercase ${
            index === activeTab ? "bg-[#06652e] text-white" : "bg-gray-300"
          }`}
        >
          {tab}
        </div>
      ))}
    </div> 
  );
}

export default TabNavigation;
