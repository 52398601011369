import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMyInventoryTableData } from "../../api/httprequests/MyInventory";

export const fetchInventoryData = createAsyncThunk(
  "myInventory/fetchData",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return [];
      }
      const myInventoryData = await getMyInventoryTableData(token);
      return myInventoryData;
    } catch (error) {
      return rejectWithValue(
        "An error occurred. Please check your network connection."
      );
    }
  }
);

const myInventorySlice = createSlice({
  name: "myInventory",
  initialState: {
    data: [],
    isLoading: true,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventoryData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchInventoryData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchInventoryData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const selectInventoryData = (state) => state.myInventory.data;
export const selectInventoryLoading = (state) => state.myInventory.isLoading;
export const selectInventoryError = (state) => state.myInventory.error;

export default myInventorySlice.reducer;
