import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFacilityName,
  setFacilityType,
  setFacilityAddress,
  setFacilityCity,
  setFacilityState,
  setFacilityTypes,
  setFacilityId,
} from "../../redux/reducers/facilitySlice";
import { getFacilityTypes } from "../../api/httprequests/FacilityTypes";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../../api/endpoints/ApiEndpoints";
import axios from "axios";
// import LoadingSpinner from "../LoadingSpinner";

const AddFacility = ({ handleNextStep }) => {
  const dispatch = useDispatch();
  const [isFacilityCreated, setIsFacilityCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    type: "",
    address: "",
    city: "",
    state: "",
  });

  const {
    facilityName,
    facilityType,
    facilityAddress,
    facilityCity,
    facilityState,
    facilityTypes,
  } = useSelector((state) => state.facility);

  // Get facility types
  useEffect(() => {
    const token = localStorage.getItem("token");
    const getFacilityTypesData = async () => {
      try {
        if (!token) {
          dispatch(setFacilityTypes([]));
          return toast.error("Please login to continue");
        }

        const response = await getFacilityTypes(token);

        if (response && response.length > 0) {
          dispatch(setFacilityTypes(response));
        }

        if (response && response.length === 0) {
          dispatch(setFacilityTypes([]));
          toast.error("No facility types found");
        }
      } catch (error) {
        dispatch(setFacilityTypes([]));
        toast.error("Error getting facility types");
      }
    };

    getFacilityTypesData();
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        dispatch(setFacilityName(value));
        setErrors({ ...errors, name: "" });
        break;
      case "type":
        dispatch(setFacilityType(value));
        setErrors({ ...errors, type: "" });
        break;
      case "address":
        dispatch(setFacilityAddress(value));
        setErrors({ ...errors, address: "" });
        break;
      case "city":
        dispatch(setFacilityCity(value));
        setErrors({ ...errors, city: "" });
        break;
      case "state":
        dispatch(setFacilityState(value));
        setErrors({ ...errors, state: "" });
        break;
      default:
        break;
    }
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    dispatch(setFacilityType(value));
    setErrors({ ...errors, type: "" });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      type: "",
      address: "",
      city: "",
      state: "",
    };

    if (facilityName.trim() === "") {
      newErrors.name = "Facility name is required";
      valid = false;
    }

    if (!facilityType) {
      newErrors.type = "Facility type is required";
      valid = false;
    }

    if (facilityAddress.trim() === "") {
      newErrors.address = "Facility address is required";
      valid = false;
    }

    if (facilityCity.trim() === "") {
      newErrors.city = "Facility city is required";
      valid = false;
    }

    if (facilityState.trim() === "") {
      newErrors.state = "Facility state is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleCreateFacility = async () => {
    const token = localStorage.getItem("token");

    const facilityData = {
      name: facilityName,
      type: facilityType,
      address: facilityAddress,
      city: facilityCity,
      state: facilityState,
    };

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        API_ENDPOINTS.CREATE_FACILITY,
        facilityData,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const createdFacilityId = response.data.data;
      dispatch(setFacilityId(createdFacilityId));
     
      if (createdFacilityId) {
   toast.success("Facility created successfully");
      }

      setIsFacilityCreated(true);
    } catch (error) {
      toast.error("Error creating facility");
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className="flex justify-center items-center ">
        <div className="w-full md:max-w-lg xl:max-w-xl m-auto rounded overflow-y-auto">
          <div className="py-4 text-left">
            <div className="flex justify-center items-center mb-4">
              <h1 className="text-2xl font-bold text-[#1F2326]">
                Add Facility
              </h1>
            </div>

            <div className="flex flex-col bg-[#E8F3FF] p-8 shadow-lg">
              <div>
                <div className="flex flex-col">
                  <label className="text-sm font-normal text-[#000000] tracking-wide">
                    Facility name
                  </label>
                  <input
                    className="text-base p-2 border border-gray-300 rounded-lg mb-4"
                    type="text"
                    name="name"
                    placeholder="Enter Facility Name"
                    value={facilityName}
                    onChange={handleInputChange}
                  />
                  {errors.name && (
                    <span className="text-red-500">{errors.name}</span>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-normal text-[#000000] tracking-wide">
                    Facility type
                  </label>

                  <div className="select-container mb-4">
                    <select
                      className="custom-select-two"
                      type="text"
                      placeholder="Enter Facility Type"
                      name="type"
                      value={facilityType}
                      onChange={handleSelectChange}
                    >
                      <option value="">Select Facility Type</option>
                      {facilityTypes &&
                        facilityTypes.length > 0 &&
                        facilityTypes.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                    <div className="dropdown-arrow-two"></div>
                  </div>
                  {errors.type && (
                    <span className="text-red-500">{errors.type}</span>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-normal text-[#000000] tracking-wide">
                    Facility address
                  </label>
                  <input
                    className="text-base p-2 border border-gray-300 rounded-lg mb-4"
                    type="text"
                    placeholder="Enter Facility Address"
                    name="address"
                    value={facilityAddress}
                    onChange={handleInputChange}
                  />
                  {errors.address && (
                    <span className="text-red-500">{errors.address}</span>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-normal text-[#000000] tracking-wide">
                    Facility city
                  </label>
                  <input
                    className="text-base p-2 border border-gray-300 rounded-lg mb-4"
                    type="text"
                    name="city"
                    placeholder="Enter Facility City"
                    value={facilityCity}
                    onChange={handleInputChange}
                  />
                  {errors.city && (
                    <span className="text-red-500">{errors.city}</span>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-normal text-[#000000] tracking-wide">
                    Facility state
                  </label>
                  <input
                    className="text-base p-2 border border-gray-300 rounded-lg mb-4"
                    type="text"
                    name="state"
                    placeholder="Enter Facility State"
                    value={facilityState}
                    onChange={handleInputChange}
                  />
                  {errors.state && (
                    <span className="text-red-500">{errors.state}</span>
                  )}
                </div>
                <div className="flex flex-col py-8 text-center w-full">
                  {isLoading ? (
                    <button
                      className="bg-[#06652e] text-base p-2 border border-gray-300 rounded-lg mb-4 shadow-lg text-[#ffff] cursor-not-allowed"
                      disabled
                    >
                      Creating...
                    </button>
                  ) : isFacilityCreated ? (
                    <button
                      className="bg-[#06652e] text-base p-2 border border-gray-300 rounded-lg mb-4 shadow-lg text-[#ffff]"
                      onClick={handleNextStep}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      className="bg-[#06652e] text-base p-2 border border-gray-300 rounded-lg mb-4 shadow-lg text-[#ffff]"
                      onClick={handleCreateFacility}
                    >
                      Create Facility
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default AddFacility;