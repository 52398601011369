import { configureStore} from "@reduxjs/toolkit";
import thunk from 'redux-thunk';
import authReducer from "./reducers/authSlice";
import gridReducer from "./reducers/gridSlice";
import inventoryReducer from "./reducers/inventorySlice";
import facilityReducer from "./reducers/facilitySlice";
import passwordReducer from "./reducers/passwordSlice";
import userReducer from "./reducers/userSlice";
import requestDataReducer from "./reducers/requestDataSlice";
import viewItem from "./reducers/viewItemSlice";
import myinventoryReducer from "./reducers/myInventorySlice";
import ordersReducer from "./reducers/ordersSlice";
import predictionReducer from "./reducers/predictionSlice"
import processedOrders from "./reducers/processedOrderSlice";
import emergencyOrderReducer from "./reducers/requestOrders";
import routineOrderReducer from "./reducers/routineOrderSlice";
import scheduleOrderReducer from "./reducers/scheduleOrderSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    grid: gridReducer,
    inventory: inventoryReducer,
    facility: facilityReducer,
    password: passwordReducer,
    user: userReducer,
    requestData: requestDataReducer,
    predictionData: predictionReducer,
    viewItem: viewItem,
    myInventory: myinventoryReducer,
    orders: ordersReducer,
    processedOrders: processedOrders,
    emergencyOrder: emergencyOrderReducer,
    routineOrder: routineOrderReducer,
    scheduleOrder: scheduleOrderReducer,
  },
  middleware: [thunk],
});

export default store;
