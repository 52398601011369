import React, { useState } from "react";

const NeedHelp = () => {
  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (sectionId) => {
    if (activeSection === sectionId) {
     
      setActiveSection(null);
    } else {
    
      setActiveSection(sectionId);
    }
  };

  return (
    <div>
      <h1 className="text-2xl font-semibold mb-4 text-white">Help</h1>
      <div>
        <Section
          title="Frequently Asked Questions"
          sectionId="faq"
          activeSection={activeSection}
          toggleSection={toggleSection}
        >
          <p>Here you can find answers to some commonly asked questions.</p>
        </Section>
        <Section
          title="Contact Us"
          sectionId="contact"
          activeSection={activeSection}
          toggleSection={toggleSection}
        >
          <p>If you need further assistance, please feel free to contact us.</p>
        </Section>
 
      </div>
    </div>
  );
};

const Section = ({
  title,
  sectionId,
  activeSection,
  toggleSection,
  children,
}) => {
  const isActive = activeSection === sectionId;

  return (
    <div className="mb-4">
      <button
        className="flex justify-between items-center w-full bg-[#fff] py-2 px-4 rounded-md focus:outline-none"
        onClick={() => toggleSection(sectionId)}
      >
        <h2 className="text-lg font-semibold text-[#06652e]">{title}</h2>
        <span
          className={`transform transition-transform ${
            isActive ? "rotate-180" : "rotate-0"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M9.29289 12.2929L13.2929 8.29289C13.6834 7.90237 14.3166 7.90237 14.7071 8.29289C15.0976 8.68342 15.0976 9.31658 14.7071 9.70711L10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L10 11.5858L13.2929 8.29289C13.6834 7.90237 14.3166 7.90237 14.7071 8.29289C15.0976 8.68342 15.0976 9.31658 14.7071 9.70711L10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L10 11.5858Z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </button>
      {isActive && <div className="bg-gray-100 py-2 px-4 mt-2">{children}</div>}
    </div>
  );
};

export default NeedHelp;
