
// // import settingsIcon from "../assets/Settings.svg";
// import DateSelect from "../components/DateSelect";
// import React from "react";
// import { useState } from "react";

// import { FaMinus } from "react-icons/fa";
// import ComparisonResult from "../components/ComparisonResult";
// import { dataSurulere } from "../constants/compareData";

// import ProfileSettings from "../components/ProfileSettings";

// const CompareFacilities = () => {
//   const [facilities, setFacilities] = useState(["", ""]);

//   const handleFacilityChange = (event, index) => {
//     const newFacilities = [...facilities];
//     newFacilities[index] = event.target.value;
//     console.log(newFacilities);
//     setFacilities(newFacilities);
//   };

//   return (
//     <div className="p-6 mt-5 pb-10">
//       <div className="flex flex-col md:flex-row justify-between items-center mb-7">
//         <h1 className="mb-3 md:mb-0 text-2xl md:text-4xl font-semibold text-[#1F2326]">
//           Compare Facilities
//         </h1>
//         <ProfileSettings />
//       </div>
//       <div className="flex flex-col md:flex-row justify-center items-center gap-5">
//         <div className="flex flex-col">
//           <div className="flex flex-row items-center justify-start gap-3">
//             <select
//               value={facilities[0]}
//               onChange={(event) => handleFacilityChange(event, 0)}
//               className="pl-4 rounded w-full md:w-36  h-11 outline-none bg-[#F8FBFF]"
//             >
//               <option value="">Select facility</option>
//               <option value="Facility 1">Facility 1</option>
//               <option value="Facility 2">Facility 2</option>
//               <option value="Facility 3">Facility 3</option>
//             </select>
//             <FaMinus className="text-black" />
//             <select
//               value={facilities[1]}
//               onChange={(event) => handleFacilityChange(event, 1)}
//               className="pl-4 rounded md:w-36  h-11 outline-none bg-[#F8FBFF]"
//             >
//               <option value="">Select facility</option>
//               <option value="Facility 1">Facility 1</option>
//               <option value="Facility 2">Facility 2</option>
//               <option value="Facility 3">Facility 3</option>
//             </select>
//           </div>
//         </div>
//         <div className="">
//           {/* DateSelect component */}
//           <DateSelect />
//         </div>
//       </div>

//       <div className="text-center">
//         <div className="mx-auto flex flex-col justify-center items-center">
//           <ComparisonResult facility1={100} facility2={60} />
//         </div>
//       </div>

//       <div className=" overflow-x-auto mt-10 mb-32 flex justify-center items-center">
//         <div className="max-w-screen-xl mx-auto">
//           <table className="w-[800px] text-center">
//             <thead className="bg-[#E8F3FF] h-16">
//               <tr className="text-[#575A5C] text-base">
//                 <th className="px-4 py-1">PHC 03 Surulere</th>
//                 <th className="px-4 py-1">PHC 01 Sangotedo</th>
//               </tr>
//             </thead>
//             <tbody>
//               {dataSurulere.map((item) => (
//                 <tr
//                   key={item.id}
//                   className="border-b  border-gray-400 border-opacity-20 bg-[#F8FBFF]"
//                 >
//                   <td className="px-4 py-2 border-r">
//                     <span className="text-[#1F2326] text-[20px] font-normal px-6">
//                       {item.product}
//                     </span>{" "}
//                     <span
//                       className={`${
//                         item.number < 50 ? "text-[#D33B27]" : "text-[#009E3F]"
//                       } text-[20px] font-semibold `}
//                     >
//                       {item.number}
//                     </span>
//                   </td>
//                   <td className="px-4 py-2">
//                     <span className="text-[#1F2326] text-[20px] font-normal px-6">
//                       {item.product}
//                     </span>{" "}
//                     <span
//                       className={`${
//                         item.number < 50 ? "text-[#D33B27]" : "text-[#009E3F]"
//                       } font-semibold text-[20px]`}
//                     >
//                       {item.number}
//                     </span>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompareFacilities;


import React from 'react'
import ComingSoon from '../components/ComingSoon'

const CompareFacilities = () => {
  return (
    <ComingSoon/>
  )
}

export default CompareFacilities