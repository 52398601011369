
import TabNavigation from "../TabNavigation";
import AddFacility from "./AddFacility";
import CreateUser from "./CreatUser";
import UploadHistoricalData from "./UploadHistoricalData";
import UploadedDataTable from "./UploadedDataTable";

import { useState } from "react";

function MultiTabForm() {
  const [activeTab, setActiveTab] = useState(0);
  const [uploadedData, setUploadedData] = useState(null);

  const handleNextStep = () => {
    setActiveTab(activeTab + 1);
  };

  const handlePrevStep = () => {
    setActiveTab(activeTab - 1);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className=" p-8">
        <TabNavigation activeTab={activeTab} setActiveTab={setActiveTab} />

        {activeTab === 0 && <AddFacility handleNextStep={handleNextStep} />}
        {activeTab === 1 && (
          <CreateUser
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
          />
        )}
        {activeTab === 2 && (
          <UploadHistoricalData
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
            uploadedResponse={uploadedData}
            setUploadResponse={setUploadedData}
          />
        )}
        {activeTab === 3 && (
          <UploadedDataTable
            data={uploadedData ? uploadedData.data : null}
            handlePrevStep={handlePrevStep}
          />
        )}
      </div>
    </div>
  );
}

export default MultiTabForm;


