/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

const Modal = ({ isOpen, onClose, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const closeModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  useEffect(() => {
    if (isModalOpen) {
      const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
          closeModal();
        }
      };

      document.addEventListener("click", handleOutsideClick);

      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }
  }, [isModalOpen]);

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-28 rounded-lg shadow-lg  w-full max-w-lg">
            <div className="flex justify-end">
              <button
                className="text-gray-600 hover:text-gray-800 transition-colors duration-150 focus:outline-none"
                onClick={closeModal}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
            <div className="mt-4 flex flex-col items-center">{children}</div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50"
          onClick={closeModal}
        ></div>
      )}
    </>
  );
};

export default Modal;
