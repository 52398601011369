import React, { useState } from "react";

const SettingsSidebar = ({ handleMenuClick }) => {
  const [activeMenuItem, setActiveMenuItem] = useState("Edit Profile");

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
    handleMenuClick(menuItem);
  };

  return (
    <div className="flex flex-col w-1/2 md:w-44 h-auto md:border-r bg-[#06652e]">
      <div className="flex-grow">
        <ul className="py-8 md:py-14">
          <li
            className={`px-6 py-2 text-[#fff] cursor-pointer ${
              activeMenuItem === "Edit Profile"
                ? " text-[#06652e]"
                : ""
            }`}
            onClick={() => handleMenuItemClick("Edit Profile")}
          >
            Edit Profile
          </li>
          <li
            className={`px-6 py-2 text-[#fff] cursor-pointer ${
              activeMenuItem === "Change Password"
                ? " text-[#06652e]"
                : ""
            }`}
            onClick={() => handleMenuItemClick("Change Password")}
          >
            Change Password
          </li>
          <li
            className={`px-6 py-2 text-[#fff] cursor-pointer ${
              activeMenuItem === "Need Help"
                ? " text-[#06652e]     "
                : ""
            }`}
            onClick={() => handleMenuItemClick("Need Help")}
          >
            Need Help
          </li>
        </ul>
      </div>
    </div>
  );
};


export default SettingsSidebar;
