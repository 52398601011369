/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../redux/reducers/inventorySlice";
import { useNavigate } from "react-router-dom";
import { getInventoryTableData } from "../../api/httprequests/InventoryTableData";
import statusOne from "../../assets/statusOne.svg";
import statusTwo from "../../assets/statusTwo.svg";
import statusThree from "../../assets/statusThree.svg";
import LoadingSpinner from "../LoadingSpinner";
import ReactPaginate from "react-paginate";
import Pagination from "../pagination/Pagination";
import useSortableTable from "../../hooks/useSortableTable";

const InventoryTable = ({ searchTerm }) => {
  const navigate = useNavigate();
  const [selectedItemId, setSelectedItemId] = React.useState(null);

  const handleViewItems = (itemId) => {
    setSelectedItemId(itemId);
    navigate(`/inventory/items/${itemId}`);
  };

  const dispatch = useDispatch();
  const inventory = useSelector((state) => state.inventory);
  const data = inventory.data;
  const [isLoading, setIsLoading] = React.useState(true);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        dispatch(setData([]));
        setIsLoading(false);
        return;
      }
      try {
        const inventoryData = await getInventoryTableData(token);
        dispatch(setData(inventoryData));
        setIsLoading(false);
      } catch (error) {
        dispatch(setData([]));
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  // Filtered data based on search term
  const filteredData = data.filter((item) =>
    (item.name || '').toLowerCase().includes((searchTerm || '').toLowerCase())
  );

  // Pagination
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredData.slice(offset, offset + itemsPerPage);

  useEffect(() => {
    setCurrentPage(0);
  }, [searchTerm]);

  const { sortedData, sortBy, sortDirection, handleSort } = useSortableTable(currentItems, 'name', 'asc');

  return (
    <div className="flex flex-col justify-between">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="max-w-full overflow-x-auto">
          <div className="table-container">
            <table className="w-full text-center">
              {/* Table headers */}
              <thead className="bg-[#06652e] h-16">
                <tr className="text-[#fff] text-base">
                  <th className="px-4 py-1" onClick={() => handleSort('name')}>
                    Facility {sortBy === 'name' && (sortDirection === 'asc' ? '▲' : '▼')}
                  </th>
                  <th className="px-4 py-1" onClick={() => handleSort('product')}>
                    Product Qty {sortBy === 'product' && (sortDirection === 'asc' ? '▲' : '▼')}
                  </th>
                  <th className="px-4 py-1" onClick={() => handleSort('product')}>
                    Last Update {sortBy === 'product' && (sortDirection === 'asc' ? '▲' : '▼')}
                  </th>
                  <th className="px-4 py-1" onClick={() => handleSort('qty')}>
                    Qty.available {sortBy === 'qty' && (sortDirection === 'asc' ? '▲' : '▼')}
                  </th>
                  <th className="px-4 py-1" onClick={() => handleSort('status')}>
                    Status {sortBy === 'status' && (sortDirection === 'asc' ? '▲' : '▼')}
                  </th>
                  <th className="px-4 py-1">Action</th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="">
                {sortedData.map((item, index) => (
                  <tr
                    key={item.id}
                    className="border-b border-gray-400 bg-[#F8FBFF] border-opacity-20"
                  >
                    <td className="px-4 py-2">{item.name}</td>
                    <td className="px-4 py-2">{item.product}</td>
                    <td className="px-4 py-2">{item.last_updated}</td>
                    <td className="px-4 py-2">{item.qty}</td>
                    <td className="px-4 py-2 flex justify-center items-center">
                      {item.status === '1' && (
                        <img className="h-6" src={statusOne} alt="Status One" />
                      )}
                      {item.status === '2' && (
                        <img className="h-6" src={statusTwo} alt="Status Two" />
                      )}
                      {item.status === '3' && (
                        <img className="h-6" src={statusThree} alt="Status Three" />
                      )}
                    </td>
                    <td className="px-0 py-2">
                      <button
                        className="bg-[#06652e] hover:bg-[#06652e] text-white font-normal py-2 px-4 rounded text-sm"
                        onClick={() => handleViewItems(item.id)}
                      >
                        View all items
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {!isLoading && filteredData.length === 0 && (
        <div className="text-center text-gray-500 mt-4">Data not available</div>
      )}
      <div className="flex justify-end mb-4">
        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
      </div>
    </div>
  );
};


export default InventoryTable;



