import { createSlice } from "@reduxjs/toolkit";

const predictionSlice = createSlice({
    name: "predictionData",
    initialState: [],
    reducers: {
        setPredictionData: (state, action) => {
            return action.payload;
        },
    },
});

export const { setPredictionData } = predictionSlice.actions;
export default predictionSlice.reducer;