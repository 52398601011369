import React, { useState } from "react";


import "./styles/Inventory.css";

import OrderProcessedTable from "../components/tables/OrderProcessedTable";
import ProcessedOrderTable from "../components/tables/ProcessedOrderTable";

import ProfileSettings from "../components/ProfileSettings";

const Orders = () => {
  const [selectedOption, setSelectedOption] = useState("orders");
  const [searchTerm, setSearchTerm] = useState("");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="p-6 pb-10">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="text-2xl md:text-4xl font-semibold text-gray-800 mt-4 md:mt-7 mb-4 md:mb-6 text-center md:text-left  flex gap-2">
          Orders
        </div>

        <ProfileSettings />
      </div>

      <div className="flex justify-between items-center mb-2">
        <div className="select-container z-30 ">
          <select
            className="custom-select"
            name=""
            id=""
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <option value="orders">Orders</option>
            <option value="processed">Processed Order</option>
          </select>
          <span className="dropdown-arrow"></span>
        </div>

        <div className="">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchTermChange}
            placeholder="Search"
            className="px-4 py-2 border border-gray-300 rounded-md"
          />
        </div>
      </div>

      {selectedOption === "orders" ? (
        <OrderProcessedTable searchTerm={searchTerm} />
      ) : (
        <>
          <ProcessedOrderTable searchTerm={searchTerm} />
        </>
      )}
    </div>
  );
};

export default Orders;
