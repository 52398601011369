const BASE_URL = "https://onebox.lifebanktest.com/api";



export const API_ENDPOINTS = {
  SIGN_IN: `${BASE_URL}/admin/login`,

  USER_INFO: `${BASE_URL}/admin/user`,
  DASHBOARD_TABLE: `${BASE_URL}/admin/dashboard`,
  INVENTORY_TABLE: `${BASE_URL}/admin/inventory/all`,
  VIEW_ITEM: `https://onebox.lifebanktest.com/api/admin/inventory/phc`,
  FACILITY_TABLE: `${BASE_URL}/admin/facility`,
  PRODUCT: `${BASE_URL}admin/predictions/product`,
  FACILITY_TYPE: `${BASE_URL}/admin/facility/type`,
  ENABLE_FACILITY: `${BASE_URL}/admin/enable/facility`,
  DISABLE_FACILITY: `${BASE_URL}/admin/disable/facility`,
  CREATE_FACILITY: `${BASE_URL}/admin/create/facility`,
  ADD_USER: `${BASE_URL}/admin/create/user`,
  MY_INVENTORY: `${BASE_URL}/admin/inventory/my`,
  UPLOAD_FILE: `${BASE_URL}/admin/data/facility/upload`,
  PROCESS_DATA: `${BASE_URL}/admin/data/facility/process/{id}`,
  DOWNLOAD_SAMPLE: `${BASE_URL}/admin/download/sample`,
  GET_FACILITY_USERS: `${BASE_URL}/admin/view/all/user`,
  PENDING_FACILITY_UPDATES: `${BASE_URL}/admin/noupdates`,
  PREDICTION: `${BASE_URL}/admin/predictions`
};


