import axios from "axios";
import { API_ENDPOINTS } from "../endpoints/ApiEndpoints";

export const getFacilityTableData = async (token) => {
  try {
    const response = await axios.get(API_ENDPOINTS.FACILITY_TABLE, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data;
  } catch (error) {
  return [];
  }
};

