import { createSlice } from "@reduxjs/toolkit";

const requestDataSlice = createSlice({
  name: "requestData",
  initialState: [],
  reducers: {
    setRequestData: (state, action) => {
      return action.payload;
    },
  },
});

export const { setRequestData } = requestDataSlice.actions;
export default requestDataSlice.reducer;