/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPredictionData } from "../../redux/reducers/predictionSlice";

import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { getPredictionData } from "../../api/httprequests/PredictionData";
import { setData } from "../../redux/reducers/gridSlice";
import Card from "../Card";
import inventoryIcon from "../../assets/InventoryIconWhite.svg";
import statusIcon from "../../assets/OneboxLogo.svg";
import scheduleIcon from "../../assets/ScheduleIcon.svg";
import ProfileSettings from "../ProfileSettings";
import useSortableTable from "../../hooks/useSortableTable";

const PredictionTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const predictionData = useSelector((state) => state.predictionData);
  const gridData = useSelector((state) => state.grid.data);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const handleNavigate = (e) => {
    e.preventDefault();
    navigate("/dashboard");
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        dispatch(
          setPredictionData({
            prediction: [],
          })
        );
        dispatch(
          setData({
            total: 0,
            facility: 0,
            product: 0,
          })
        );
        return;
      }

      const { predictionCard, predictionData } = await getPredictionData(token);
      dispatch(setPredictionData(predictionData));
      dispatch(setData(predictionCard));
    };

    fetchData();
  }, [dispatch]);

  const { facility_name } = predictionData.length > 0 ? predictionData[0] : {};
  const handleSeeMore = (e) => {
    e.preventDefault();

    const totalItems = predictionData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
  };

  const { sortedData, sortBy, sortDirection, handleSort } = useSortableTable(
    predictionData,
    "id",
    "asc"
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = sortedData.slice(startIndex, endIndex);

  const tableHeaders = [
    { label: "PRODUCT ID", columnName: "id", defaultSortDirection: "asc" },
    { label: "Product name", columnName: "facility_name" },
    { label: "Product Current Qty", columnName: "qty" },
    { label: "Product Predicted Qty", columnName: "prediction" },
    { label: "Difference", columnName: "difference" },
  ];

  const getArrowIcon = (columnName) => {
    if (sortBy === columnName) {
      return sortDirection === "asc" ? "▲" : "▼";
    }
    return "";
  };

  return (
    <>
      <div className="overflow-x-auto">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <Link className="mb-3 md:mb-0" to="">
            {/* Predictions */}
          </Link>

          <ProfileSettings />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-6 gap-4 h-[100%] mb-14">
          <div className="w-full cursor-pointer">
            <Link to="#">
              <Card
                icon={inventoryIcon}
                count={gridData ? gridData.total : 0}
                description="Quarter Prediction"
                background="bg-[#0080FF]"
              />
            </Link>
          </div>
          <div className="w-full cursor-pointer">
            <Link to="/new-facilities">
              <Card
                icon={scheduleIcon}
                count={gridData ? gridData.facility : 0}
                description="Facility"
                background="bg-[#A56DD4]"
              />
            </Link>
          </div>
          <div className="w-full cursor-pointer">
            <Link to="/new-predict">
              <Card
                icon={statusIcon}
                count={gridData ? gridData.product : 0}
                description="Product"
                background="bg-[#D33B27]"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center">
        <h2
          className="font-semibold text-2xl mb-2 md:mb-0 md:mr-4"
          onClick={handleNavigate}
        >
          Current Prediction Requirements
        </h2>
      </div>
      <div className="overflow-x-auto mb-10">
        <div className="min-w-full shadow-lg">
          <table className="w-full text-center shadow-lg">
            <thead className="bg-[#06652e]">
              <tr className="text-[#ffff] text-base">
                {tableHeaders.map((header) => (
                  <th
                    key={header.columnName}
                    className="py-4 px-2 sm:px-4 md:px-6 lg:px-8 cursor-pointer"
                    onClick={() =>
                      header.columnName !== "action"
                        ? handleSort(header.columnName)
                        : undefined
                    }
                  >
                    {header.label}{" "}
                    <span>{getArrowIcon(header.columnName)}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData.length === 0 ? (
                <tr>
                  <td
                    colSpan={6}
                    className="text-4xl font-bold text-center mb-2 mt-2 h-28"
                  >
                    No Pending Predictions
                  </td>
                </tr>
              ) : (
                paginatedData.map((item) => (
                  <tr
                    key={item.product}
                    className="border-b border-gray-400 border-opacity-20 bg-[#F8FBFF]"
                  >
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.product}
                    </td>
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.name}
                    </td>
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.qty}
                    </td>
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.prediction}
                    </td>
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.difference}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className=" flex justify-end items-center mb-20">
        <p
          className="text-[#06652e] hover:underline cursor-pointer text-2xl"
          onClick={handleSeeMore}
        >
          See more - {currentPage} of{" "}
          {Math.ceil(predictionData.length / itemsPerPage)}
        </p>
      </div>
    </>
  );
};

export default PredictionTable;
