import React from "react";
import FacilityTable from "../components/tables/FacilityTable";

import ProfileSettings from "../components/ProfileSettings";

const Facilities = ({ facility_name }) => {
  return (
    <div className="p-6 mt-5 pb-10">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <h1 className="mb-3 md:mb-0 text-2xl md:text-4xl font-semibold text-[#1F2326]">
          Facilities
        </h1>
        <ProfileSettings facility_name={facility_name} />
      </div>
      <div>
        <FacilityTable />
      </div>
    </div>
  );
};

export default Facilities;
