import React from "react";

const DisplayProofOfPayment = ({ pofUrl }) => {
  return (
    <div className="flex justify-center items-center h-screen">
      <img src={pofUrl} alt="Proof of Payment" className="w-50 h-46" />
    </div>
  );
};

export default DisplayProofOfPayment;
