import React from "react";

import { AiOutlineSetting } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const ProfileSettings = ({ facility_name }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/settings");
  };

  return (
    <div
      className="flex justify-end items-end md:items-center md:ml-4 cursor-pointer"
      onClick={handleNavigate}
    >
      <div className="mb-0 mr-2"> {facility_name}</div>
      <AiOutlineSetting className="w-6 h-6" />
    </div>
  );
};

export default ProfileSettings;
