/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Button from "../Button";
import { toast } from "react-toastify";
import axios from "axios";
import LoadingSpinner from "../LoadingSpinner";
import { useDispatch } from "react-redux";
import { fetchInventoryData } from "../../redux/reducers/myInventorySlice";

const AddInventory = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [product, setProduct] = useState("");
  const [category, setCategory] = useState("");
  const [formulation, setFormulation] = useState("");
  const [qty, setQty] = useState("");
  const [pricing, setPricing] = useState("");
  const [sku, setSku] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      product,
      category,
      formulation,
      qty,
      pricing,
      sku,
    };

    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://onebox.lifebanktest.com/api/admin/product/add/new",
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success("Data successfully updated");
      closeModal();
      dispatch(fetchInventoryData());

      setProduct("");
      setCategory("");
      setFormulation("");
      setQty("");
      setPricing("");
      setSku("");
    } catch (error) {
      toast.error("Error updating data");
    }

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-md mx-auto">
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="product"
        >
          Product
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="product"
          type="text"
          placeholder="Enter product"
          value={product}
          onChange={(e) => setProduct(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="category"
        >
          Category
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="category"
          type="text"
          placeholder="Enter category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="formulation"
        >
          Formulation
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="formulation"
          type="text"
          placeholder="Enter formulation"
          value={formulation}
          onChange={(e) => setFormulation(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="quantity"
        >
          Qty. Available
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="quantity"
          type="text"
          placeholder="Enter quantity available"
          value={qty}
          onChange={(e) => setQty(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="price"
        >
          Price
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="price"
          type="text"
          placeholder="Enter price"
          value={pricing}
          onChange={(e) => setPricing(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="sku"
        >
          SKU
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="sku"
          type="text"
          placeholder="Enter SKU"
          value={sku}
          onChange={(e) => setSku(e.target.value)}
        />
      </div>
      <div className="flex items-center justify-center">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Button
            color="primary"
            size="large"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Add to Inventory
          </Button>
        )}
      </div>
    </form>
  );
};

export default AddInventory;
