import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Dashboard,
  Inventory,
  Settings,
  Facilities,
  CompareFacilities,
  SignInPage,
} from "./pages/index.js";
import Sidebar from "./components/Sidebar.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Orders from "./pages/Orders.jsx";
import ViewItems from "./components/ViewItem.jsx";
import { useState } from "react";
import ScheduledReq from "./components/requests/ScheduledReq.jsx";
import EmergencyReq from "./components/requests/EmergencyReq.jsx";
import RoutineReq from "./components/requests/RoutineReq.jsx";
import ScheduledOrder from "./components/orders/ScheduledOrder.jsx";
import EmergencyOrder from "./components/orders/EmergencyOrder.jsx";
import RoutineOrder from "./components/orders/RoutineOrder.jsx";
import Predictions from "./pages/Predictions.jsx";
import NewFacility from "./components/tables/NewFacility.jsx";
import NewPredict from "./pages/NewPredict.jsx";

import MultiTabForm from "./components/forms/MultiTabForm.jsx";

import OrderDetailsPage from "./pages/OrderDetailsPage.jsx";
import FacilityUsers from "./components/tables/FacilityUsers.jsx";
import DisplayProofOfPayment from "./pages/DisplayProofOfPayment.jsx";
import Breadcrumb from "./components/Breadcrumb.jsx";
import OrderHistory from "./pages/OrderHistory.jsx";
import ComingSoon from "./components/ComingSoon.jsx";
import PendingFacilities from "./pages/PendingFacilities.jsx";
import FacilityProduct from "./components/tables/FacilityProduct.jsx";

const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.log("isAuthenticated", isAuthenticated);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Router>
      <>
        {isAuthenticated ? (
          <div className="flex h-full">
            <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <div className="flex-grow overflow-y-auto md:ml-[256px]">
              <Breadcrumb />
              <Routes>
                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path="/dashboard/emergency" element={<EmergencyReq />} />
                <Route
                  path="/dashboard/emergency/:itemId"
                  element={<EmergencyOrder />}
                />
                <Route
                  path="/dashboard/:type/:itemId"
                  element={<EmergencyOrder />}
                />
                <Route path="dashboard/schedule" element={<ScheduledReq />} />
                <Route path="/new-facilities" element={<NewFacility />} />
                <Route
                  path="/dashboard/schedule/:itemId"
                  element={<ScheduledOrder />}
                />
                <Route path="/dashboard/routine" element={<RoutineReq />} />
                <Route
                  path="/dashboard/routine/:itemId"
                  element={<RoutineOrder />}
                />
                <Route path="/settings" element={<Settings />} />
                <Route path="/inventory" element={<Inventory />} />
                <Route path="/facilities" element={<Facilities />} />
                <Route path="/facilities/add" element={<MultiTabForm />} />
                <Route
                  path="/facilities/users/:id"
                  element={<FacilityUsers />}
                />
                <Route
                  path="/facilities/product/:id"
                  element={<FacilityProduct />}
                />
                <Route path="/orders" element={<Orders />} />

                <Route path="/order" element={<OrderDetailsPage />} />
                <Route path="/order/history" element={<OrderHistory />} />
                <Route path="/pof" element={<DisplayProofOfPayment />} />
                <Route path="/coming-soon" element={<ComingSoon />} />
                <Route
                  path="/pending-facilities"
                  element={<PendingFacilities />}
                />

                <Route
                  path="/compare-facilities"
                  element={<CompareFacilities />}
                />
                <Route path="/predictions" element={<Predictions />} />
                <Route path="/new-predict" element={<NewPredict />} />
                <Route
                  path="/inventory/items/:itemId"
                  element={<ViewItems />}
                />
                <Route path="*" element={<h1>Not Found</h1>} />
              </Routes>
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<SignInPage />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        )}
        <ToastContainer />
      </>
    </Router>
  );
};

export default App;
