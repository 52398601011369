import React from "react";

const NoOrders = () => {
  return (
    <div>
      <h1 className="text-2xl font-semibold text-gray-800 mt-4 md:mt-7 mb-4 md:mb-6 text-center md:text-left  flex gap-2">
        No Orders Available
      </h1>
    </div>
  );
};

export default NoOrders;
