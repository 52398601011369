import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingSpinner from "../LoadingSpinner";
import { fetchProcessedOrders } from "../../redux/reducers/processedOrderSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "../pagination/Pagination";
import useSortableTable from "../../hooks/useSortableTable";

const ProcessedOrderTable = ({ searchTerm }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.processedOrders);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const itemsPerPage = 8;

  useEffect(() => {
    dispatch(fetchProcessedOrders());
  }, [dispatch]);

  useEffect(() => {
    if (data && data.length > 0) {
      const filtered = data.filter(
        (item) =>
          (item.id &&
            item.id.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.type &&
            item.type.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.facility_name &&
            item.facility_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredData(filtered);
    }
  }, [data, searchTerm]);

  const fetchOrderDetails = async (itemId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `hhttps://onebox.lifebanktest.com/api/admin/process/${itemId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching order details:", error);
      return null;
    }
  };
    const { sortedData, sortBy, sortDirection, handleSort } = useSortableTable(
    filteredData, "id", "asc"
  );
 const handleViewOrder = async (id) => {
   try {
     setIsLoadingOrder(true);
     const orderDetails = await fetchOrderDetails(id);

     if (orderDetails && Object.keys(orderDetails).length > 0) {
       navigate("/order", { state: { order: orderDetails } });
     } else {
       console.error("Error fetching order details");
     }
   } catch (error) {
     console.error("Error fetching order details:", error);
   } finally {
     setIsLoadingOrder(false); 
   }
 };

 if (isLoading || isLoadingOrder) {
    return <LoadingSpinner />;
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
 const paginatedData = sortedData.slice(startIndex, endIndex);

  return (
    <>
      <div className="max-w-full overflow-x-auto">
        <table className="w-full text-center shadow-lg">
          <thead className="bg-[#06652e] h-16">
            <tr className="text-[#fff] text-base">
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("id")}
              >
                ID{" "}
                {sortBy === "id" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("type")}
              >
                Order Type{" "}
                {sortBy === "type" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("facility_name")}
              >
                Facility Name{" "}
                {sortBy === "facility_name" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("status")}
              >
                Status{" "}
                {sortBy === "status" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("updated")}
              >
                Dispatch Date{" "}
                {sortBy === "updated" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th className="px-4 py-1">View Order</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item) => (
              <tr key={item.id} className="border-b bg-[#F8FBFF]">
                <td className="px-4 py-1">{item.id}</td>
                <td className="px-4 py-1">{item.type}</td>
                <td className="px-4 py-1">{item.facility_name}</td>
                <td className="px-4 py-1">{item.status}</td>
                <td className="px-4 py-1">{item.updated}</td>
                <td className="px-4 py-1">
                  <button
                    className="bg-[#06652e] hover:bg-[#06652e] text-[#F8FBFF] font-normal py-2 px-4 rounded text-sm"
                    onClick={() => handleViewOrder(item.id)}
                  >
                    View order
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end mb-4">
        <Pagination
          pageCount={Math.ceil(filteredData.length / itemsPerPage)}
          onPageChange={({ selected }) => setCurrentPage(selected + 1)}
          forcePage={currentPage - 1}
        />
      </div>
    </>
  );
};

export default ProcessedOrderTable;


