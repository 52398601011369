/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "../../pages/styles/Inventory.css";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import Pagination from "../pagination/Pagination";
import useSortableTable from "../../hooks/useSortableTable";

const NewFacility = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAllItems, setShowAllItems] = useState(false);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const getData = async () => {
    try {
      const response = await axios.get(
        "https://onebox.lifebanktest.com/api/admin/predictions/facility",
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const filteredFacilityData =
    data.length > 0
      ? data.filter((item) =>
          item.facility_name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : [];

  const { sortedData, sortBy, sortDirection, handleSort } = useSortableTable(
    filteredFacilityData,
    "name",
    "asc"
  );

  useEffect(() => {
    setShowAllItems(false);
    setCurrentPage(1);
  }, [searchQuery]);

  const handleFetchUsers = async (id) => {
    try {
      setIsLoading(true);
      navigate(`/facilities/product/${id}`);
    } catch (error) {
      toast.error("Error fetching users");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentItems = showAllItems
    ? sortedData
    : sortedData.slice(startIndex, endIndex);

  const hasNoItems = filteredFacilityData.length === 0;

  const totalPages = Math.ceil(filteredFacilityData.length / itemsPerPage);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  return (
    <>
      <div className="flex justify-center items-center mt-8 mb-8 md:justify-between md:items-center">
        <div>
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-md py-2 px-4 block w-72 appearance-none leading-normal"
          />
        </div>
      </div>

      <div className="max-w-full overflow-x-auto">
        <table className="w-full  text-center">
          <thead className="bg-[#06652e] h-16">
            <tr className="text-[#fff] text-base">
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("facility")}
              >
                ID{" "}
                {sortBy === "facility" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("facility_name")}
              >
                Facility{" "}
                {sortBy === "facility_name" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("facility_type_name")}
              >
                Type{" "}
                {sortBy === "facility_type_name" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("prediction_id")}
              >
                Prediction ID{" "}
                {sortBy === "prediction_id" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("prediction_id")}
              >
                View Product{" "}
                {sortBy === "prediction_id" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => (
              <tr
                key={item.facility}
                className="border-b border-gray-400 border-opacity-20 bg-[#F8FBFF]"
              >
                <td className="px-4 py-1 ">{item.facility}</td>
                <td className="px-4 py-1 ">{item.facility_name}</td>
                <td className="px-4 py-1 ">{item.facility_type}</td>
                <td className="px-4 py-1 ">{item.prediction_id}</td>
                <td className="px-4 py-1">
                  <button
                    className="bg-[#06652e] hover:bg-[#2C2E82] text-[#F8FBFF] font-normal py-1 px-2 rounded text-sm"
                    onClick={() => handleFetchUsers(item.facility)}
                  >
                    View Product
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end items-center mb-2">
        {!showAllItems && filteredFacilityData.length > itemsPerPage && (
          <div className="flex justify-center ">
            <Pagination
              pageCount={totalPages}
              onPageChange={handlePageChange}
              forcePage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default NewFacility;
