/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoOrders from "../NoOrders";
import LoadingSpinner from "../LoadingSpinner";
import axios from "axios";
import { dispatchOrder, fetchOrders } from "../../redux/reducers/ordersSlice";
import Success from "../notifcations/Success";
import { useNavigate } from "react-router-dom";
import Pagination from "../pagination/Pagination";
import Button from "../Button";
import Modal from "../Modal";
import useSortableTable from "../../hooks/useSortableTable";
import { toast } from "react-toastify";

const OrderProcessedTable = ({ searchTerm }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.orders);
  const [filteredData, setFilteredData] = useState([]);
  const [showOrderSuccess, setShowOrderSuccess] = useState(false);
  const [successTimeout, setSuccessTimeout] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const itemsPerPage = 8;

  const [pofUrl, setPofUrl] = useState(null);

  const renderProofOfPayment = (pof) => {
    if (!pof) {
      return <span>No Proof of Payment</span>;
    }

    return (
      <Button
        className="bg-[#0080FF] hover:bg-[#2C2E82] text-[#F8FBFF] font-normal py-2 px-4 rounded text-sm"
        onClick={() => setPofUrl(pof)}
      >
        View file
      </Button>
    );
  };

  const { sortedData, sortBy, sortDirection, handleSort } = useSortableTable(
    filteredData,
    "id",
    "asc"
  );
  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  useEffect(() => {
    if (data.length > 0) {
      const filtered = data.filter(
        (item) =>
          item.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.facility_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.status.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [data, searchTerm]);

  const fetchOrderDetails = async (itemId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `https://onebox.lifebanktest.com/api/admin/process/${itemId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching order details:", error);
      return null;
    }
  };

  const fetchOrder = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `https://onebox.lifebanktest.com/api/admin/process/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const data = response.data.facility.pof;
      if(data === null){
        toast.error("No payment available at the moment")
      } else {
        setShowOrderSuccess(true);
      }
      // setValue(data)
      // console.log(data, "ooo");
      await axios.post(
        `https://onebox.lifebanktest.com/api/admin/dispatch/${id}`,
          data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } catch (error) {
      return error;
    }
  };


  // const handleDispatchOrder = async (id) => {
  

  //   dispatch(dispatchOrder(id));
  //   setShowOrderSuccess(true);
  
  //   const orderDetails = await fetchOrderDetails(id);
    
  // };

  const handleViewOrder = async (id) => {
    try {
      setIsLoadingOrder(true);
      const orderDetails = await fetchOrderDetails(id);

      if (orderDetails && Object.keys(orderDetails).length > 0) {
        navigate("/order", { state: { order: orderDetails } });
      } else {
        console.error("Error fetching order details");
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    } finally {
      setIsLoadingOrder(false);
    }
  };

  const handleBack = async () => {
    showOrderSuccess && setShowOrderSuccess(false);
    dispatch(fetchOrders());
    navigate("/orders");
  };

  if (isLoading || isLoadingOrder) {
    return <LoadingSpinner />;
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = sortedData.slice(startIndex, endIndex);

  if (filteredData.length === 0) {
    return (
      <div className="flex justify-center items-center h-[70vh]">
        <NoOrders />
      </div>
    );
  }

  if (showOrderSuccess) {
    return (
      <div className="flex justify-center items-center h-[50vh]">
        <Success notification={"Dispatch sent"} handleBack={handleBack} />
      </div>
    );
  }

  return (
    <>
      <div className="max-w-full overflow-x-auto">
        <table ModalclassName="w-full text-center shadow-lg">
          <thead className="bg-[#06652e] h-16">
            <tr className="text-[#fff] text-base">
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("id")}
              >
                Order ID{" "}
                {sortBy === "id" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("type")}
              >
                Order Type{" "}
                {sortBy === "type" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("facility_name")}
              >
                Facility Name{" "}
                {sortBy === "facility_name" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th className="px-4 py-1">Proof of payment</th>
              <th
                className="px-4 py-1 cursor-pointer"
                onClick={() => handleSort("status")}
              >
                Status{" "}
                {sortBy === "status" && (
                  <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                )}
              </th>
              <th className="px-4 py-1">Dispatch</th>
              <th className="px-4 py-1">View Order</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item) => (
              <tr key={item.id} className="border-b bg-[#F8FBFF]">
                <td className="px-4 py-1">{item.id}</td>
                <td className="px-4 py-1">{item.type}</td>
                <td className="px-4 py-1">{item.facility_name}</td>
                <td className="px-4 py-1">{renderProofOfPayment(item.pof)}</td>
                <td className="px-4 py-1">{item.status}</td>
                <td className="px-4 py-1">
                  <button
                    className="bg-[#06652e] hover:bg-[#06652e] text-[#F8FBFF] font-normal py-2 px-4 rounded text-sm"
                    onClick={() => fetchOrder(item.id)}
                  >
                    Send to dispatch
                  </button>
                </td>
                <td className="px-4 py-1">
                  <button
                    className="bg-[#06652e] hover:bg-[#06652e] text-[#F8FBFF] font-normal py-2 px-4 rounded text-sm"
                    onClick={() => handleViewOrder(item.id)}
                  >
                    View order
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="">
          {pofUrl && (
            <Modal isOpen={true} onClose={() => setPofUrl(null)}>
              <img
                src={pofUrl}
                alt="Proof of Payment"
                // className="h-40"
              />
            </Modal>
          )}
        </div>
      </div>
      <div className="flex justify-end mb-4">
        <Pagination
          pageCount={Math.ceil(filteredData.length / itemsPerPage)}
          onPageChange={({ selected }) => setCurrentPage(selected + 1)}
          forcePage={currentPage - 1}
        />
      </div>
    </>
  );
};

export default OrderProcessedTable;





