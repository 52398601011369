import axios from "axios";
import { API_ENDPOINTS } from "../endpoints/ApiEndpoints";

export const getFacilityTypes = async (token) => {
  try {
    const response = await axios.get(API_ENDPOINTS.FACILITY_TYPE, {
      headers: {
        Authorization: token,
      },
    });

    if (response.data && response.data.status && response.data.data) {
      const facilityTypesData = response.data.data;
      const typesArray = Object.values(facilityTypesData);

      return typesArray;
    } else {

      return [];
    }
  } catch (error) {
    
    return [];
  }
};
