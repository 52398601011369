import { useLocation, Link } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");


  const removeNumericParameter = (segment) => {
    return segment.replace(/\d+$/, ""); 
  };


  if (pathSegments.length > 0) {
    const lastSegment = pathSegments[pathSegments.length - 1];
    const isParameterized = lastSegment.match(/\d+$/); 
    if (isParameterized) {
      pathSegments[pathSegments.length - 1] =
        removeNumericParameter(lastSegment);
    }
  }

  return (
    <nav aria-label="breadcrumb">
      <ol className="flex p-2 rounded-md bg-[#fff]  shadow-lg space-x-2">
        <li>
          <Link to="/dashboard" className="text-[#06652e]">
            Dashboard
          </Link>
        </li>
        {pathSegments.map((segment, index) => (
          <li key={index}>
            <span className="mx-2">/</span>
            {index === pathSegments.length - 1 ? (
              <span className="font-semibold">{segment}</span>
            ) : (
              <Link
                to={`/${pathSegments.slice(0, index + 1).join("/")}`}
                className="text-[#06652e]"
              >
                {segment}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
