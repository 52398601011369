import React from "react";
import comingsoon from "../assets/comingsoon.png";

const ComingSoon = () => {
  return (
    <div
      className="flex items-center justify-center h-[90vh] bg-gray-100 p-6"
      style={{
        backgroundImage: `url(${comingsoon})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="text-center">
        <h2 className="text-6xl font-bold mb-4 text-[#06652e]">Coming Soon!</h2>
        <p className="text-[#000] text-2xl mb-28">
          We are working hard to bring you something amazing.
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
