import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  facilityId: null,
  facilityName: "",
  facilityType: "",
  facilityAddress: "",
  facilityCity: "",
  facilityState: "",
  facilityTemplateUpload: {
    file: null,
    status: "idle",
  },
  facilityTypes: [],
  facilityData: [],
  facilityUsers: [],
};

const facilitySlice = createSlice({
  name: "facility",
  initialState,
  reducers: {
    setFacilityId: (state, action) => {
      state.facilityId = action.payload;
    },
    setFacilityName: (state, action) => {
      state.facilityName = action.payload;
    },
    setFacilityType: (state, action) => {
      state.facilityType = action.payload;
    },
    setFacilityAddress: (state, action) => {
      state.facilityAddress = action.payload;
    },
    setFacilityCity: (state, action) => {
      state.facilityCity = action.payload;
    },
    setFacilityState: (state, action) => {
      state.facilityState = action.payload;
    },
    setFacilityTemplateFile: (state, action) => {
      state.facilityTemplateUpload.file = action.payload;
    },
    setFacilityTemplateUploadStatus: (state, action) => {
      state.facilityTemplateUpload.status = action.payload;
    },
    setFacilityTypes: (state, action) => {
      state.facilityTypes = action.payload;
    },
    setFacilityData: (state, action) => {
      state.facilityData = action.payload;
    },
    setFacilityUsers: (state, action) => {
      state.facilityUsers = action.payload;
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setProcesData: (state, action) => {
      state.processData = action.payload;
    },
  },
});

export const {
  setFacilityName,
  setFacilityType,
  setFacilityAddress,
  setFacilityCity,
  setFacilityState,
  setFacilityTemplateFile,
  setFacilityTemplateUploadStatus,
  setFacilityTypes,
  setFacilityData,
  setFacilityUsers,
  setIsModalOpen,
  setProcesData,
  setFacilityId,
} = facilitySlice.actions;

export default facilitySlice.reducer;
