/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import settingsIcon from "../assets/Settings.svg";
import InventoryTable from "../components/tables/InventoryTable";
import MyInventoryTable from "../components/tables/MyInventoryTable";
import "./styles/Inventory.css";
import UserInfo from "../components/UserInfo";
import { useSelector } from "react-redux";
import { AiOutlineSetting } from "react-icons/ai";
import ProfileSettings from "../components/ProfileSettings";
import Button from "../components/Button";
import Modal from "../components/Modal";
import AddInventory from "../components/forms/AddInventory";
import UpdateInventory from "../components/forms/UpdateInventory";

const Inventory = ({ facility_name }) => {
  const inventory = useSelector((state) => state.inventory);
  const myInventory = useSelector((state) => state.myInventory);
  const data = inventory?.data || [];
  const data2 = myInventory?.data || [];

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedOption, setSelectedOption] = useState("facility-inventory");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  const token = localStorage.getItem("token");

 
  const dataSource = selectedOption === "facility-inventory" ? data : data2;


  const filteredData = dataSource.filter((item) =>
    item.product.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="p-6 pb-10">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="text-2xl md:text-4xl font-semibold text-gray-800 mt-4 md:mt-7 mb-4 md:mb-6 text-center md:text-left  flex gap-2">
          Inventory
        </div>

        <ProfileSettings />
      </div>

      <div className="flex justify-between items-center mb-4">
        <div className="select-container ">
          <select
            className="custom-select"
            name=""
            id=""
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <option value="facility-inventory">Facility Inventory</option>
            <option value="my-inventory">My Inventory</option>
          </select>
          <span className="dropdown-arrow"></span>
        </div>

        <div className="hidden md:flex justify-end items-center  gap-3 ">
          {selectedOption === "my-inventory" ? (
            <Button onClick={handleClick} color="primary" size="">
              Add to Inventories
            </Button>
          ) : (
            <div></div>
          )}
          <input
            type="text"
            placeholder="Search..."
            className="bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-md py-2 px-4 block w-72 appearance-none leading-normal"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="flex md:hidden justify-end items-end  ">
          <input
            type="text"
            placeholder="Search..."
            className="border border-gray-300rounded px-4 py-2  focus:outline-none w-full"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {selectedOption === "facility-inventory" ? (
        <InventoryTable currentItems={currentItems} searchTerm={searchTerm} />
      ) : (
        <MyInventoryTable currentItems={currentItems} searchTerm={searchTerm} />
      )}

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <h2 className="text-xl font-bold mb-4">Add to Inventories</h2>
          <AddInventory  closeModal={closeModal}/>
        </Modal>
      )}

    </div>
  );
};



export default Inventory;
