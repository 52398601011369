/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import LoadingSpinner from "../components/LoadingSpinner";
import DashboardTable from "../components/tables/DashboardTable";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1200));

      setIsDataLoaded(true);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className="p-6 mt-4 pb-10 max-h-screen">
      <DashboardTable />

      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default Home;
