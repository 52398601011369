import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../components/pagination/Pagination";

const OrderDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { order } = location.state;

  const handleBack = () => {
    navigate('/orders');
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = order.data.slice(startIndex, endIndex);

  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold mb-4">Order Details</h1>
          <button
            className="bg-[#06652e] hover:bg-[#06652e] text-[#F8FBFF] font-normal py-2 px-4 rounded text-sm"
            onClick={handleBack}
          >
            Back to orders
          </button>
        </div>

        <table className="w-full text-center shadow-lg">
          <thead className="bg-[#06652e] h-16">
            <tr className="text-[#fff] text-base">
              {/* <th className="p-2 bg-gray-200 border">ID</th> */}
              <th className="px-4 py-1">Name</th>
              <th className="px-4 py-1">Formulation</th>
              <th className="px-4 py-1">Items</th>
              <th className="px-4 py-1">Quantity</th>
              <th className="px-4 py-1">Available Quantity</th>
              <th className="px-4 py-1">Status</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item) => (
              <tr key={item.id} className="text-center border-b bg-[#F8FBFF]">
                {/* <td className="p-2 border">{item.id}</td> */}
                <td className="px-4 py-1">{item.name}</td>
                <td className="px-4 py-1">{item.formulation}</td>
                <td className="px-4 py-1">{item.items}</td>
                <td className="px-4 py-1">{item.qty}</td>
                <td className="px-4 py-1">{item.available_qty}</td>
                <td className="px-4 py-1">{item.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end mb-2">
          <Pagination
            pageCount={Math.ceil(order.data.length / itemsPerPage)}
            onPageChange={({ selected }) => handlePageChange(selected + 1)}
            forcePage={currentPage - 1}
          />
        </div>
      </div>
    </>
  );
};



export default OrderDetailsPage;
