/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setUsername,
  setPassword,
  setEmail,
  setPhone,
} from "../../redux/reducers/userSlice";

import { API_ENDPOINTS } from "../../api/endpoints/ApiEndpoints";
import axios from "axios";


const CreateUser = ({ handleNextStep, handlePrevStep }) => {
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const username = useSelector((state) => state.user.username);
  const password = useSelector((state) => state.user.password);
  const email = useSelector((state) => state.user.email);
  const phone = useSelector((state) => state.user.phone);
  const facilityId = useSelector((state) => state.facility.facilityId);

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "username":
        dispatch(setUsername(value));
        break;
      case "password":
        dispatch(setPassword(value));
        break;
      case "email":
        dispatch(setEmail(value));
        break;
      case "phone":
        dispatch(setPhone(value));
        break;
      default:
        break;
    }
  };

  const validateInputs = () => {
    let newErrors = {};

    if (!username.trim()) {
      newErrors.username = "Username is required.";
    }

    if (!password.trim()) {
      newErrors.password = "Password is required.";
    }

    if (!email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!isValidEmail(email)) {
      newErrors.email = "Invalid email format.";
    }

    if (!phone.trim()) {
      newErrors.phone = "Phone is required.";
    } else if (!isValidPhone(phone)) {
      newErrors.phone = "Invalid phone number format.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const isValidEmail = (email) => {
    return /^\S+@\S+\.\S+$/.test(email);
  };

  const isValidPhone = (phone) => {
    return /^[0-9]{11}$/.test(phone);
  };

  const handleCreateUser = async () => {
    if (!validateInputs()) {
      return;
    }

    try {
      setIsLoading(true);

      const token = localStorage.getItem("token");

      const response = await axios.post(
        API_ENDPOINTS.ADD_USER,
        {
          username,
          password,
          email,
          phone,
          facility_id: facilityId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      handleNextStep();
    } catch (error) {
      if (error.response && error.response.data) {
        const { message } = error.response.data;

        if (
          message.includes("Duplicate entry") &&
          message.includes("for key 'username'")
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            username: "Username already exists.",
          }));
        } else if (
          message.includes("Duplicate entry") &&
          message.includes("for key 'email'")
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Email already exists.",
          }));
        } else if (
          message.includes("Duplicate entry") &&
          message.includes("for key 'phone'")
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            phone: "Phone number already exists.",
          }));
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!facilityId) {
      handlePrevStep();
    }
  }, [facilityId, handlePrevStep]);

  return (
    <>
      <div className="flex justify-center items-center">
        <div className="w-full md:max-w-lg xl:max-w-xl m-auto rounded overflow-y-auto">
          <div className="py-4 text-left">
            <div className="flex justify-center items-center mb-4">
              <h1 className="text-2xl font-bold text-[#1F2326]">Create User</h1>
            </div>

            <div className="flex flex-col bg-[#E8F3FF] p-8 shadow-lg">
              <div>
                <div className="flex flex-col">
                  <label className="text-sm font-normal text-[#000000] tracking-wide">
                    Username
                  </label>
                  <input
                    className="text-base p-2 border border-gray-300 rounded-lg mb-4"
                    type="text"
                    name="username"
                    placeholder="Enter Username"
                    value={username}
                    onChange={handleInputChange}
                  />
                  {errors.username && (
                    <p className="text-red-500">{errors.username}</p>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-normal text-[#000000] tracking-wide">
                    Password
                  </label>
                  <input
                    className="text-base p-2 border border-gray-300 rounded-lg mb-4"
                    type="password"
                    name="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={handleInputChange}
                  />
                  {errors.password && (
                    <p className="text-red-500">{errors.password}</p>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-normal text-[#000000] tracking-wide">
                    Email
                  </label>
                  <input
                    className="text-base p-2 border border-gray-300 rounded-lg mb-4"
                    type="email"
                    name="email"
                    placeholder="Enter Email"
                    value={email}
                    onChange={handleInputChange}
                  />
                  {errors.email && (
                    <p className="text-red-500">{errors.email}</p>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-normal text-[#000000] tracking-wide">
                    Phone
                  </label>
                  <input
                    className="text-base p-2 border border-gray-300 rounded-lg mb-4"
                    type="text"
                    name="phone"
                    placeholder="Enter Phone"
                    value={phone}
                    onChange={handleInputChange}
                  />
                  {errors.phone && (
                    <p className="text-red-500">{errors.phone}</p>
                  )}
                </div>
                <div className="flex flex-col py-8 text-center w-full">
                  <button
                    className="bg-[#06652e] text-base p-2 border border-gray-300 rounded-lg mb-4 shadow-lg text-[#ffff]"
                    onClick={handleCreateUser}
                    disabled={isLoading}
                  >
                    {isLoading ? "Creating..." : "Create User"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateUser;
