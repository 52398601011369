/* eslint-disable no-unused-vars */
import React, { useState} from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination/Pagination"

const UploadedDataTable = ({ data, handlePrevStep }) => {
  const navigate = useNavigate();
  const facilityId = useSelector((state) => state.facility.facilityId);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const [isLoading, setIsLoading] = useState(false);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const processedData = async () => {
    setIsLoading(true);

    try {
      const token = localStorage.getItem('token');

      const response = await axios.post(
        `https://onebox.lifebanktest.com/api/admin/data/facility/process`,
        {
          facility_id: facilityId,
          data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success('Successful');
      navigate('/dashboard');
    } catch (error) {
      toast.error('Error processing data');
    }

    setIsLoading(false);
  };

  const renderTable = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const visibleData = data.slice(startIndex, endIndex);

    return (
      <>
        <div className="flex flex-col items-center justify-center ">
          <h2 className="text-xl font-bold mb-2">Historical Data</h2>
          <div className="overflow-x-auto">
            <div className="flex justify-between mb-5">
              <button
                className="bg-[#E8F3FFE5] py-2 px-4 text-gray-700 font-bold rounded"
                onClick={handlePrevStep}
              >
                Previous
              </button>

              <button
                className="bg-[#2C2E82] py-2 px-4 text-white font-bold rounded"
                onClick={processedData}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Verify"}
              </button>
            </div>
            <table className="w-full border border-gray-300">
              <thead>
                <tr className="bg-[#E8F3FFE5]">
                  <th className="py-2 px-4 border-b border-gray-300 font-bold text-sm text-gray-700 sticky top-0">
                    Drugs
                  </th>
                  <th className="py-2 px-4 border-b border-gray-300 font-bold text-sm text-gray-700 sticky top-0">
                    Category
                  </th>
                  <th className="py-2 px-4 border-b border-gray-300 font-bold text-sm text-gray-700 sticky top-0">
                    Formulation
                  </th>
                  <th className="py-2 px-4 border-b border-gray-300 font-bold text-sm text-gray-700 sticky top-0">
                    Dte.Delivered
                  </th>
                  <th className="py-2 px-4 border-b border-gray-300 font-bold text-sm text-gray-700 sticky top-0">
                    Qty.Ordered
                  </th>
                  <th className="py-2 px-4 border-b border-gray-300 font-bold text-sm text-gray-700 sticky top-0">
                    Duration
                  </th>
                </tr>
              </thead>
              <tbody>
                {visibleData.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, index) => (
                      <td
                        key={index}
                        className="py-2 px-4 border-b border-gray-300 text-sm text-gray-700 text-center"
                      >
                        {value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-end items-end ">
              <Pagination
                pageCount={totalPages}
                onPageChange={handlePageChange}
                forcePage={currentPage - 1}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return renderTable();
};



export default UploadedDataTable;
