/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setRequestData } from "../../redux/reducers/requestDataSlice";

import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { getDashboardData } from "../../api/httprequests/DboardTableData";
import { setData } from "../../redux/reducers/gridSlice";
import Card from "../Card";
import Recalibrate from "./Raclibrate";
import ScheduledReq from "../requests/ScheduledReq";
import EmergencyReq from "../requests/EmergencyReq";
import RoutineReq from "../requests/RoutineReq";
import inventoryIcon from "../../assets/InventoryIconWhite.svg";
import scheduleIcon from "../../assets/ScheduleIcon.svg";
import UserInfo from "../UserInfo";
import ProfileSettings from "../ProfileSettings";
import useSortableTable from "../../hooks/useSortableTable";
import { formatDate } from "../../utils/DateUtli";
import Pagination from "../pagination/Pagination";
import { getPendingFacilities } from "../../api/httprequests/getPendingFacilities";

const DashboardTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const requestData = useSelector((state) => state.requestData);
  const gridData = useSelector((state) => state.grid.data);
  // console.log("gridData", gridData);
  const [pendingFacilities, setPendingFacilities] = useState(0)

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [intervalId, setIntervalId] = useState(null);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };
  const handleNavigate = (e) => {
    e.preventDefault();
    navigate("/dashboard");
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        dispatch(
          setRequestData({
            request: [],
          })
        );
        dispatch(
          setData({
            box: 0,
            scheduled: 0,
            emergency: 0,
            routine: 0,
            Special: 0,
          })
        );
        return;
      }

      const { dashboardData, requestData } = await getDashboardData(token);
      const pendingFacilities = await getPendingFacilities()
      setPendingFacilities(pendingFacilities?.total || 0)
      dispatch(setRequestData(requestData));
      dispatch(setData(dashboardData));
    };

    fetchData();
  }, [dispatch]);

  const { facility_name } = requestData.length > 0 ? requestData[0] : {};
  const handleSeeMore = (e) => {
    e.preventDefault();

    const totalItems = requestData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
  };

 
  const getColorByType = (type) => {
    switch (type) {
      case "Emergency":
        return "#D33B27";
      case "Schedule":
        return "#0080FF";
      case "Routine":
        return "#2C2E82";
      default:
        return "#000000";
    }
  };

  const handleViewOrder = (itemId, type) => {
    setSelectedItemId(itemId);
    navigate(`/dashboard/${type.toLowerCase()}/${itemId}`);
  };

  const { sortedData, sortBy, sortDirection, handleSort } = useSortableTable(
    requestData,
    "id",
    "asc"
  );

   const startIndex = (currentPage - 1) * itemsPerPage;
   const endIndex = startIndex + itemsPerPage;
   const paginatedData = sortedData.slice(startIndex, endIndex);


  const tableHeaders = [
    { label: "RQ ID", columnName: "id", defaultSortDirection: "asc" },
    { label: "Facility", columnName: "facility_name" },
    { label: "Order Type", columnName: "type" },
    { label: "Due Date", columnName: "created" },
    { label: "No. of Products", columnName: "product" },
    { label: "Action", columnName: "action" },
  ];

  const getArrowIcon = (columnName) => {
    if (sortBy === columnName) {
      return sortDirection === "asc" ? "▲" : "▼";
    }
    return "";
  };

  return (
    <>
      <div className="overflow-x-auto">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <Link className="mb-3 md:mb-0" to="/dashboard">
            Dashboard
          </Link>

          <ProfileSettings />
        </div>
        <div className="text-2xl md:text-4xl font-semibold text-gray-800 mt-4 md:mt-7 mb-4 md:mb-6 text-center md:text-left flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="flex items-center justify-center md:justify-start gap-2">
            Hello <UserInfo />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-6 gap-4 h-[100%] mb-14">
          <div className="w-full cursor-pointer">
            <Link to="/inventory">
              <Card
                icon={inventoryIcon}
                count={gridData ? gridData.box : 0}
                description="Total no. of facilities"
                background="bg-[#0080FF]"
              />
            </Link>
          </div>
          <div className="w-full cursor-pointer">
            <Link to="/dashboard/schedule">
              <Card
                icon={scheduleIcon}
                count={gridData ? gridData.scheduled : 0}
                description="Top up"
                background="bg-[#A56DD4]"
              />
            </Link>
          </div>
          <div className="w-full cursor-pointer">
            <Link to="/dashboard/emergency">
              <Card
                icon={inventoryIcon}
                count={gridData ? gridData.emergency : 0}
                description="Emergency requests"
                background="bg-[#D33B27]"
              />
            </Link>
          </div>
          <div className="w-full cursor-pointer">
            <Link to="/dashboard/routine">
              <Card
                icon={scheduleIcon}
                count={gridData ? gridData.routine : 0}
                description="Routine requests"
                background="bg-[#06652e]"
              />
            </Link>
          </div>
          <div className="w-full cursor-pointer">
            <Link to="/pending-facilities">
              <Card
                icon={scheduleIcon}
                count={pendingFacilities}
                description="Pending Facility Updates"
                background="bg-[#F7C245]"
              />
            </Link>
          </div>
          <div className="w-full">
            <Recalibrate />
          </div>
        </div>

        <div className="overflow-auto">
          <Routes>
            <Route path="/schedule" element={<ScheduledReq />} />
            <Route path="/emergency" element={<EmergencyReq />} />
            <Route path="/routine" element={<RoutineReq />} />
            {/* <Route path="/" element={<DashboardTable />} /> */}
          </Routes>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center">
        <h2
          className="font-semibold text-2xl mb-2 md:mb-0 md:mr-4"
          onClick={handleNavigate}
        >
          Pending requests
        </h2>
      </div>
      <div className="overflow-x-auto mb-10">
        <div className="min-w-full shadow-lg">
          <table className="w-full text-center shadow-lg">
            <thead className="bg-[#06652e]">
              <tr className="text-[#ffff] text-base">
                {tableHeaders.map((header) => (
                  <th
                    key={header.columnName}
                    className="py-4 px-2 sm:px-4 md:px-6 lg:px-8 cursor-pointer"
                    onClick={() =>
                      header.columnName !== "action"
                        ? handleSort(header.columnName)
                        : undefined
                    }
                  >
                    {header.label}{" "}
                    <span>{getArrowIcon(header.columnName)}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData.length === 0 ? (
                <tr>
                  <td
                    colSpan={6}
                    className="text-4xl font-bold text-center mb-2 mt-2 h-28"
                  >
                    No Pending Requests
                  </td>
                </tr>
              ) : (
                paginatedData.map((item) => (
                  <tr
                    key={item.id}
                    className="border-b border-gray-400 border-opacity-20 bg-[#F8FBFF]"
                  >
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.id}
                    </td>
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.facility_name}
                    </td>
                    <td
                      className="py-4 px-2 sm:px-4 md:px-6 lg:px-8"
                      style={{ color: getColorByType(item.type) }}
                    >
                      {item.type === "Schedule" ? "Top up" : item.type}
                    </td>
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.created}
                    </td>
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.product}
                    </td>
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {(item.type === "Emergency" ||
                        item.type === "Schedule" ||
                        item.type === "Routine") && (
                        <button
                          className="bg-[#06652e] hover:bg-[#06652e] text-[#F8FBFF] font-normal py-2 px-4 rounded text-sm"
                          onClick={() => handleViewOrder(item.id, item.type)}
                        >
                          View Order
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className=" flex justify-end items-center mb-20">
        <p
          className="text-[#06652e] hover:underline cursor-pointer text-2xl"
          onClick={handleSeeMore}
        >
          See more - {currentPage} of{" "}
          {Math.ceil(requestData.length / itemsPerPage)}
        </p>
      </div>

      {selectedItemId && (
        <>
          {requestData.find((item) => item.id === selectedItemId)?.type ===
            "Emergency" && (
            <Navigate to={`/dashboard/emergency/${selectedItemId}`} />
          )}
          {requestData.find((item) => item.id === selectedItemId)?.type ===
            "Schedule" && (
            <Navigate to={`/dashboard/schedule/${selectedItemId}`} />
          )}
          {requestData.find((item) => item.id === selectedItemId)?.type ===
            "Routine" && (
            <Navigate to={`/dashboard/routine/${selectedItemId}`} />
          )}
        </>
      )}
    </>
  );
};

export default DashboardTable;