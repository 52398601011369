import React from "react";

const EditProfile = () => {
  return (
    <div>
      <form>
        <div className="flex flex-col mb-4">
          <label
            className="text-[#fff] text-sm font-normal mb-2"
            htmlFor="username"
          >
            Username
          </label>
          <input
            className="border border-[#fff] p-2 mb-2 outline-none bg-[#fff]"
            type="text"
            name="username"
            id="username"
            placeholder="Username"
          />
        </div>
        <div className="flex flex-col mb-4">
          <label
            className="text-[#fff] text-sm font-normal mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="border border-[#ffff] p-2 mb-2 outline-none bg-[#fff]"
            type="email"
            name="email"
            id="email"
            placeholder="Email"
          />
        </div>
        <div className="flex lg:justify-start  lg:items-start md:justify-center md:items-center w-full">
          <button className="w-full sm:w-auto bg-[#fff] hover:bg-[#fff] text-[#06652e] font-semibold py-2 px-4 sm:py-3 sm:px-6 lg:text-lg rounded">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
