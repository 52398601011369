import React from "react";

const Button = ({ children, onClick, color, size, className }) => {
  const getColorClasses = () => {
    switch (color) {
      case "primary":
        return "bg-[#06652e] hover:bg-[#06652e] text-white";
      case "secondary":
        return "bg-[#06652e] hover:bg-[#06652e]text-white";
      case "success":
        return "bg-[#06652e] hover:bg-[#06652e] text-white";
      case "danger":
        return "bg-[#06652e] hover:bg-[#06652e] text-white";
      default:
        return "bg-[#06652e] hover:bg-[#06652e] text-white";
    }
  };

  const getSizeClasses = () => {
    switch (size) {
      case "small":
        return "py-1 px-2 text-sm";
      case "large":
        return "py-3 px-6 text-lg";
      default:
        return "py-2 px-4";
    }
  };

  return (
    <button
      className={`inline-block rounded focus:outline-none ${getColorClasses()} ${getSizeClasses()} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
