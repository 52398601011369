import React from 'react'
import PendingFacilitiesTable from '../components/tables/PendingFacilitiesTable'

export default function PendingFacilities() {
  return (
    <div>
      <PendingFacilitiesTable />
    </div>
  )
}
