import axios from "axios";
import { API_ENDPOINTS } from "../endpoints/ApiEndpoints";

export const getDashboardData = async (token) => {
  try {
    const response = await axios.get(API_ENDPOINTS.DASHBOARD_TABLE, {
      headers: {
        Authorization: token,
      },
    });

    const requestData = response.data.data.request || [];
    const dashboardData = response.data.data || {
      box: 0,
      scheduled: 0,
      emergency: 0,
      routine: 0,
    };

    return { requestData, dashboardData };
  } catch (error) {
    return {
      requestData: [],
      dashboardData: { box: 0, scheduled: 0, emergency: 0, routine: 0 },
    };
  }
};
