import React from "react";

const ErrorComponent = ({ message }) => {
  return (
    <div className="flex items-center justify-center mt-4">
      <div className="flex items-center bg-red-500 text-white font-bold rounded-lg border shadow-lg p-4">
        <svg
          className="h-6 w-6 mr-2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="currentColor"
            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 7H13V13H11V7ZM11 15H13V17H11V15Z"
          />
        </svg>
        <span>{message}</span>
      </div>
    </div>
  );
};

export default ErrorComponent;
